import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import AlarmIcon from 'shared-vectors/icons/BellFilled';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Button from 'shared-components/components/Button';

import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import trackTLEvents from 'shared-utils/src/trackTLEvents';
import sleep from 'shared-utils/src/sleep';
import openSaveSearchModal from 'shared-utils/src/saveSearch/openSaveSearchModal';

import { useCtxGlobal } from '@client/app/Container';

import {
  saveSearchQb,
  createGenericAfterSpecificModal,
  createFeedbackModal,
  createNewSaveSearchFeedbackModal,
} from '@modals/factories';

import './style.scss';

const OptSaveSearchBanner = (props) => {
  const {
    id,
    scrollInfos,
    setSaveSearchBannerVisibile,
    setForcedAuction,
    checkProposeBkmAfterLead,
    userLogIn,
  } = props;

  const {
    openModal,
    closeModal,
    globalSelector,
    globalReducer,
    globalState: {
      config: {
        cookiesConfig = {},
        api: {
          account,
          authBasePrefix,
        },
      } = {},
    } = {},
    isMobile,
    siteUrl,
    saveBookmarkSuccess,
    filtersProps,
    filtersProps: {
      publisherName,
    },
    isSmallMobile,
    setNewSearchFromSaveSearchModal,
    pageType,
    model: {
      total,
    },
    user,
    userConf: {
      userUrls: {
        userhost,
      },
    },
    isAuth,
    getSearchesList,
    constantsCopy,
  } = useCtxGlobal();

  const scrollEl = useRef(null);
  const [ringing, setRinging] = useState(false);
  const [offSetTop, setOffSetTop] = useState(null);

  useEffect(
    () => {
      if (
        offSetTop
        && scrollInfos.coords + (document.documentElement.clientHeight / 2) >= offSetTop
      ) {
        isMobile && setRinging(true);
        setSaveSearchBannerVisibile();
      }
    }, [scrollInfos, offSetTop],
  );

  useEffect(
    () => {
      const {
        current: {
          offsetTop,
        },
      } = scrollEl;
      setOffSetTop(offsetTop);
    }, [],
  );

  const saveSearch = () => {
    const activeNearby = globalSelector('activeNearby');
    const poiNamesList = globalSelector('poiNamesList');
    const deleteBookmarkSuccess = () => {
      globalReducer('deleteBookmark', null);
      checkProposeBkmAfterLead();
    };
    const disableProposeBkm = () => globalReducer('disableProposeBkmAfterLead');
    const closeModalAction = payload => closeModal(payload.close);

    openSaveSearchModal({
      openModalAction: openModal,
      closeModalAction,
      createNewSaveSearchModal: saveSearchQb,
      createGenericAfterSpecificModal,
      createNewSaveSearchFeedbackModal,
      createFeedbackModal,
      getSearchesList,
      isMobile,
      isSmallMobile,
      filtersProps,
      copyModalTitle: constantsCopy,
      saveSearchProps: {
        domain: siteUrl,
        mycasaDomain: userhost,
        accountDomain: account,
        authBasePrefix,
        app: 'P18',
        disableProposeBkm,
        activeNearby,
        poiNamesList,
        searchIsSaved: false,
        bookmarkData: null,
        publisherName,
        setForcedAuction,
        saveBookmarkSuccess,
        deleteBookmarkSuccess,
        setNewSearchFromSaveSearchModal,
        total,
        isFromNotRegisteredUser: !isAuth,
        user,
        logIn: userLogIn,
        cookiesConfig,
        isFromPropose: false,
        positionGTM: 'SRP_ListingBanner',
      },
      panelChooseBy: {
        isMobile,
        actionClicked: 'srpSaveSearch',
        pageFrom: pageType,
      },
    });
  };

  const saveSearchProxy = () => {
    sleep(300).then(() => {
      trackGTMEvents({
        category: 'FavouriteAndAlert',
        action: 'OpenAlertModal',
        label: 'notSavedYet',
        position: 'SRP_ListingBanner',
      }, null, true);
      trackTLEvents({
        evObj: {
          event: {
            type: 'view',
            name: 'saveSearch',
          },
        },
      });
    });
    saveSearch();
  };

  const mainCls = makeClassName([
    'optBnr mb--m c-bg--w b--s tp-s--m',
    'pr--ml pb--l pl--ml',
    isMobile ? 'pt--ml ml--s mr--s tp-a--c' : 'pt--l is-clickable',
  ]);

  const titCls = makeClassName([
    'optBnr__tit optBnr__txt tp-w--l',
    isMobile && 'mb--xs tp-s--x2l',
  ]);

  const mouseOver = () => {
    if (!isMobile) {
      setRinging(true);
    }
  };

  const mouseOut = () => {
    if (!isMobile) {
      setRinging(false);
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Grid
      id={id}
      className={mainCls}
      direction={isMobile ? 'column' : ''}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <GridItem behavior="fixed" align="middle" className="optBnr__bell-cnt">
        <Icon
          name={AlarmIcon}
          size="ms"
          className={ringing ? 'optBnr__bell' : ''}
          fill
        />
      </GridItem>
      <GridItem className="optBnr__ctn">
        <strong ref={scrollEl} className={titCls}>Avvisami dei nuovi annunci</strong>
        <p className="optBnr__txt">Vuoi ricevere in tempo reale i nuovi annunci pubblicati per questa ricerca?</p>
      </GridItem>
      <GridItem behavior="fixed" align="middle">
        <Button
          className="optBnr__btn"
          action={saveSearchProxy}
        >
          <span>Avvisami</span>
        </Button>
      </GridItem>
    </Grid>
  );
};
OptSaveSearchBanner.propTypes = {
  id: PropTypes.string,
  scrollInfos: PropTypes.instanceOf(Object),
  setSaveSearchBannerVisibile: PropTypes.func,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
};
OptSaveSearchBanner.defaultProps = {
  id: null,
  scrollInfos: {},
  setSaveSearchBannerVisibile: () => { },
  setForcedAuction: () => { },
  checkProposeBkmAfterLead: () => { },
  userLogIn: () => { },
};
export default OptSaveSearchBanner;
