import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alarm from 'shared-vectors/icons/BellFilled';
import { Icon } from 'shared-vectors';

import openSaveSearchModal from 'shared-utils/src/saveSearch/openSaveSearchModal';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';
import { getItem } from 'shared-utils/src/user/helpers';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import { useCtxGlobal } from '@client/app/Container';

import {
  saveSearchQb,
  createGenericAfterSpecificModal,
  createFeedbackModal,
  createNewSaveSearchFeedbackModal,
} from '@modals/factories';

import './critical.scss';

const messages = {
  default: (
    <>
      <strong className="tp-w--l c-txt--f0">Avvisami </strong>
      dei nuovi annunci pubblicati per questa ricerca
    </>
  ),
  fromProposeSaveSearch: (
    <>
      <strong className="tp-w--l c-txt--f0">Attiva le notifiche </strong>
      e non perderti nessuna occasione!
    </>
  ),
};

const ProposeBaloon = ({
  baloonType,
  baloonFrom,
  publisherName,
  setForcedAuction,
  checkProposeBkmAfterLead,
  userLogIn,
}) => {
  const {
    openModal,
    closeModal,
    globalState: {
      config: { cookiesConfig = {}, api: { account, authBasePrefix } } = {},
    } = {},
    globalSelector,
    globalReducer,
    isMobile,
    siteUrl,
    saveBookmarkSuccess,
    filtersProps,
    isSmallMobile,
    setNewSearchFromSaveSearchModal,
    pageType,
    model: { total },
    user,
    userConf: {
      userUrls: { userhost },
    },
    isAuth,
    getSearchesList,
    constantsCopy,
  } = useCtxGlobal();

  const [copy, setCopy] = useState('default');
  const includePropose = ['fromProposeServer', 'exactSearch'].includes(baloonType);
  useEffect(() => {
    sleep(300).then(trackGTMEvents(
      {
        category: 'FavouriteAndAlert',
        action: 'ShowSaveSearchBanner',
        label: baloonType === 'exactSearch' ? 'UserSearch' : 'GenericSearch',
        from: baloonFrom,
      },
      {},
      true,
    ));
  }, [baloonType, baloonFrom]);

  useEffect(() => {
    const st = getItem('hintTooltipSaveSearch');
    if (st === 'fromProposeSaveSearch') {
      setCopy('fromProposeSaveSearch');
    }
  }, []);

  const saveSearch = () => {
    const searchIsSaved = globalSelector('searchIsSaved');
    const activeNearby = globalSelector('activeNearby');
    const poiNamesList = globalSelector('poiNamesList');
    const bookmarkData = globalSelector('bookmarkData');
    const deleteBookmarkSuccess = () => {
      globalReducer('deleteBookmark', null);
      checkProposeBkmAfterLead();
    };
    const disableProposeBkm = () => globalReducer('disableProposeBkmAfterLead');
    const closeModalAction = payload => closeModal(payload.close);
    openSaveSearchModal({
      openModalAction: openModal,
      closeModalAction,
      createNewSaveSearchModal: saveSearchQb,
      createGenericAfterSpecificModal,
      createNewSaveSearchFeedbackModal,
      createFeedbackModal,
      getSearchesList,
      isMobile,
      isSmallMobile,
      filtersProps,
      copyModalTitle: constantsCopy,
      saveSearchProps: {
        domain: siteUrl,
        mycasaDomain: userhost,
        accountDomain: account,
        authBasePrefix,
        app: 'P18',
        disableProposeBkm,
        activeNearby,
        poiNamesList,
        searchIsSaved,
        bookmarkData,
        publisherName,
        setForcedAuction,
        saveBookmarkSuccess,
        deleteBookmarkSuccess,
        setNewSearchFromSaveSearchModal,
        total,
        isFromNotRegisteredUser: !isAuth,
        user,
        logIn: userLogIn,
        cookiesConfig,
        positionGTM: 'SRP_Baloon',
        isFromPropose: true,
        propose: {
          from: includePropose ? baloonFrom : null,
          searchType: includePropose
            ? 'UserSearch'
            : 'GenericSearch',
        },
      },
      panelChooseBy: {
        isMobile,
        actionClicked: 'srpSaveSearch',
        pageFrom: pageType,
      },
    });
  };

  const saveSearchProxy = () => {
    const saveSearchEvt = {
      category: 'FavouriteAndAlert',
      action: 'OpenAlertModal',
      label: 'notSavedYet',
      from: includePropose ? baloonFrom : null,
      position: 'SRP_Baloon',
    };

    sleep(300).then(() => trackGTMEvents(saveSearchEvt, {}, true));
    saveSearch();
  };

  return (
    <>
      <div className="prpsBln">
        <Grid
          align="center"
          className="prpsBln__cont c-bg--w b--s is-clickable is-rel p--s"
          onClick={saveSearchProxy}
        >
          <GridItem className="prpsBln__icon pr--s" behavior="fixed">
            <Icon name={Alarm} fill size="ml" />
          </GridItem>

          <GridItem className="prpsBln__txt tp-s--l tp-w--s c-txt--secondary mr--xs">
            {messages[copy]}
          </GridItem>
          <GridItem className="mr--s" behavior="fixed">
            <button
              type="button"
              className="prpsBln__btn--save p--s tp-w--m tp-s--l c-bg--w c-txt--f0"
            >
              Avvisami
            </button>
          </GridItem>
        </Grid>
      </div>
    </>
  );
};

export default ProposeBaloon;

ProposeBaloon.propTypes = {
  baloonType: PropTypes.string,
  baloonFrom: PropTypes.string,
  publisherName: PropTypes.string,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
};

ProposeBaloon.defaultProps = {
  baloonType: null,
  baloonFrom: null,
  publisherName: null,
  setForcedAuction: () => { },
  checkProposeBkmAfterLead: () => { },
  userLogIn: () => { },
};
