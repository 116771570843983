import React from 'react';
import PropTypes from 'prop-types';

import './critical.scss';

const SidebarBanner = ({ show, children }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="srp-sb-banner pt--s pb--s pl--m pr--m mt--ml pl--m pr--m c-bg--w b--s">
      {children}
    </div>
  );
};

SidebarBanner.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

SidebarBanner.defaultProps = {
  show: true,
};

export default SidebarBanner;
