import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Arrow from 'shared-vectors/icons/GalleryArrowSml';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import Card from './components/Card';

import './critical.scss';

const NewGeoReach = ({
  list,
  visible,
  setRef,
}) => {
  const slider = useRef(null);
  const [slideState, setSlideState] = useState({
    current: 0,
    coords: 0,
  });

  useEffect(() => {
    setRef(slider.current);
  }, [slider, setRef]);

  const prevSlide = () => {
    const { current, coords } = slideState;
    const newCurrent = current - 1;

    let newCoords = newCurrent * 566;
    if (newCurrent === list.length - 1) {
      newCoords = coords - ((566 + 550) - slider.current.clientWidth);
    }
    setSlideState({
      current: newCurrent,
      coords: newCoords,
    });    
  };

  const nextSlide = () => {
    const { current, coords } = slideState;
    const newCurrent = current + 1;
    
    let newCoords = newCurrent * 566;
    if (newCurrent === list.length) {
      newCoords = coords + ((566 + 550) - slider.current.clientWidth);
    }

    setSlideState({
      current: newCurrent,
      coords: newCoords,
    });
  };
  
  return (
    <div ref={slider} className="srpGReach is-rel">
      <Grid
        style={{ width: `${(list.length + 1) * 566}px`, transform: `translate(-${slideState.coords}px, 0)` }}
        className="srpGReach__list"
        noWrap
      >
        {list.map((project, index) => <Card key={project.id} project={project} visible={visible} index={index} />)}
        <GridItem
          behavior="no-shrink"
          className={`srpGReach__list__item srpGReach__list__ph${visible ? ' wi' : ''} p--m c-bg--w b--s is-rel`}
        >
          <p className="srpGReach__list__ph--tit pb--s tp-s--l tp-w--l c-txt--f0">Lasciati incuriosire dalle case nuove della tua zona!</p>
          <p className="srpGReach__list__ph--p tp-s--s tp-w--s c-txt--f5">Dai un’occhiata alle case in costruzione nei comuni vicini a quello che hai impostato nella tua ricerca.</p>
        </GridItem>
      </Grid>
      {slideState.current > 0 && (
        <button
          type="button"
          className="srpGReach__arrows mr--s c-txt--secondary b--0 b-r--pill is-clickable"
          onClick={prevSlide}
        >
          <Icon name={Arrow} width="13px" height="13px" />
        </button>
      )}
      {slideState.current < list.length && (
        <button
          type="button"
          className="srpGReach__arrows right c-txt--secondary b--0 b-r--pill is-clickable"
          onClick={nextSlide}
        >
          <Icon name={Arrow} width="13px" height="13px" className="rotate--180" />
        </button>
      )}
    </div>
  );
};

export default NewGeoReach;

NewGeoReach.propTypes = {
  list: PropTypes.instanceOf(Array),
  visible: PropTypes.bool,
  setRef: PropTypes.func,
};

NewGeoReach.defaultProps = {
  list: null,
  visible: false,
  setRef: () => {},
};
