/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { computeActiveFilters } from 'shared-utils/src/helpersMyCasa';

import { Icon } from 'shared-vectors';
import FiltersIcon from 'shared-vectors/icons/Filters';

import { CtxContainer } from '@client/app/Container';
import { queryBuilder } from '@modals/factories';

import { isDrawonMapSearch } from '../../../helpers/search/Search';

import { isChildOf } from '../../../helpers/DOMHelpers';

import './critical.scss';
import SaveSearchButton from '../SaveSearchButton';
import SortComponent from './SortComponent';
import GoToMapButton from './GoToMapButton';

const propTypes = {
  mapUrl: PropTypes.string,
  mapLinkAvailable: PropTypes.bool,
  sortChange: PropTypes.func,
  sortType: PropTypes.string,
  sortValuesMap: PropTypes.instanceOf(Object),
  publisherName: PropTypes.string,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
  viewport: PropTypes.string,
};

const defaultProps = {
  mapUrl: '',
  mapLinkAvailable: false,
  sortChange: () => {},
  sortType: '',
  sortValuesMap: {},
  publisherName: null,
  setForcedAuction: () => {},
  checkProposeBkmAfterLead: () => {},
  userLogIn: () => {},
  viewport: '',
};

class TopButtons extends React.Component {
  static contextType = CtxContainer;

  constructor(props) {
    super(props);

    this.state = {
      sortSelectIsOpen: false,
      sortValue: props.sortType,
    };
  }

  closeSelectOnOutClick = (e) => {
    let node = null;
    const { sortSelectIsOpen } = this.state;
    if (sortSelectIsOpen) {
      node = this.sortWrapper;
    }
    if (!node) {
      return;
    }
    const { target } = e;
    if (!isChildOf(target, node)) {
      this.setState({ sortSelectIsOpen: false }, () => {
        window.removeEventListener('click', this.closeSelectOnOutClick);
      });
    }
  };

  setShowQRCode = (show) => {
    this.setState({ showQRCode: show });
  };

  toggleSortSelect = () => {
    this.setState(
      prevState => ({ sortSelectIsOpen: !prevState.sortSelectIsOpen }),
      () => {
        window.addEventListener('click', e => this.closeSelectOnOutClick(e));
      },
    );
  };

  sortChange = (val, dontTriggerToggle = false) => {
    const { sortChange } = this.props;
    this.setState({ sortValue: val }, () => {
      sortChange(val);
      if (!dontTriggerToggle) {
        this.toggleSortSelect();
      }
    });
  };

  goToMap = () => {
    if (window.localStorage) {
      window.localStorage.setItem('_searchMapBackLinkURL', document.location);
      window.localStorage.removeItem('_map_lastActiveMarker');
    }
    document.location = this.props.mapUrl;
  };

  openMobileSearch = () => {
    const {
      openModal,
      isMobile,
      isSmallMobile,
      filtersProps,
      pageType,
    } = this.context;

    console.log('**Action clicked: filtersHeader**');
    openModal &&
      openModal(
        queryBuilder({
          isMobile,
          isSmallMobile,
          filtersProps,
          panelChooseBy: {
            isMobile,
            actionClicked: 'filtersHeader',
            pageFrom: pageType,
          },
        }),
      );
  };

  render() {
    const {
      sortValuesMap,
      mapLinkAvailable,
      publisherName,
      setForcedAuction,
      checkProposeBkmAfterLead,
      userLogIn,
      viewport,
    } = this.props;
    const { sortValue, sortSelectIsOpen } = this.state;

    const {
      filtersProps: { filters, locations, isAgencySrp },
      model: { total, fewResults },
      constantsCopy: {
        SRP_SAVE_SEARCH_BTN_SAVE_SEARCH_MOBILE,
        SRP_EDIT_SEARCH_BTN_SAVE_SEARCH_MOBILE,
      },
    } = this.context;

    const showOnlyFiltersBtn =
      isAgencySrp || total === 0 || (fewResults && fewResults.page > 1);

    const showSaveSearchButton = !isAgencySrp;
    const { activeFiltersCount } = computeActiveFilters(filters);
    const activeFilters =
      activeFiltersCount > 0 ? <em>{activeFiltersCount}</em> : null;
    const btnsClass = 'srp-topbtns default-btn ';

    return (
      <div className={btnsClass}>
        <button
          type="button"
          aria-label="Apri i filtri di ricerca"
          className="search default-btn"
          onClick={this.openMobileSearch}
        >
          <span className="icon-cont">
            <Icon name={FiltersIcon} width="24px" height="24px" fill />
          </span>
          <div className="text">
            <div className="mr--s">{'Filtri '}</div>
            {activeFilters}
          </div>
        </button>
        {(!showOnlyFiltersBtn || isDrawonMapSearch(locations, filters)) && (
          <GoToMapButton
            clsBtn="default-btn"
            goToMap={this.goToMap}
            mapLinkAvailable={mapLinkAvailable}
          />
        )}
        {(total > 0 || (fewResults && fewResults.page > 1)) && (
          <SortComponent
            viewport={viewport}
            setSortWrapper={(n) => {
              this.sortWrapper = n;
            }}
            sortSelectIsOpen={sortSelectIsOpen}
            showOnlyFiltersBtn={showOnlyFiltersBtn}
            sortValue={sortValue}
            sortValuesMap={sortValuesMap}
            toggleSortSelect={this.toggleSortSelect}
            sortChange={this.sortChange}
            isAgencySrp={isAgencySrp}
          />
        )}
        {showSaveSearchButton && (
          <SaveSearchButton
            publisherName={publisherName}
            setForcedAuction={setForcedAuction}
            checkProposeBkmAfterLead={checkProposeBkmAfterLead}
            userLogIn={userLogIn}
            notShownOn="desktop"
            positionGTM="SRP_PageTitle"
            copySaveSearch={{
              title: SRP_SAVE_SEARCH_BTN_SAVE_SEARCH_MOBILE,
              editTitle: SRP_EDIT_SEARCH_BTN_SAVE_SEARCH_MOBILE,
            }}
            showSaveSearchBannerGreenHint={false}
          />
        )}
      </div>
    );
  }
}

TopButtons.propTypes = propTypes;
TopButtons.defaultProps = defaultProps;
export default TopButtons;
