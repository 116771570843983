import React from 'react';
import PropTypes from 'prop-types';

import setFromConfiguration from 'shared-utils/src/trackGTMEvents/setFromConfiguration';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import './style.scss';

const NewGeoReachCard = ({ project, index, visible }) => {
  const {
    uri,
    description,
    geoInfos: {
      street,
      city,
      province,
    },
    projectData: {
      photo,
      price,
      size,
      rooms,
      name,
    },
    title: {
      main,
    },
    publisher: {
      publisherName,
      publisherUrl,
      publisherLogo,
      hasCrossSubscription,
    },
  } = project;

  const logoStr = publisherLogo || '';
  const hasPlaceholder = !!(logoStr.indexOf('agencylogo') > -1 || logoStr.indexOf('placeholder') > -1);
  const logo = hasPlaceholder ? logoStr.replace('/', '') : logoStr;

  const resize = hasCrossSubscription
    ? '125x60-resize/'
    : '';

  const logoImg = !hasPlaceholder
    ? (
      <img
        className="srpGReach__list__item__pub__logo mr--s pm is-rel"
        src={visible ? `https://images-1.casa.it/${resize}${logo}` : null}
        alt={publisherName}
      />
    ) : (
      <span className="srpGReach__list__item__pub__name tp-s--x2s tp-w--s c-txt--f5">
        {publisherName}
      </span>
    );

  const trackClick = () => {
    setFromConfiguration({
      url: uri,
      value: 'GeoReachCarousel',
      eventsList: ['SendEmail', 'CallPhone'],
    });
    trackGTMEvents({
      category: 'Interaction',
      action: 'GoToProject',
      label: index + 1,
      position: 'SRP_Carousel',
    });
  };

  const getPriceVal = (obj) => {
    if (obj.min !== '0' && obj.max !== '0') {
      return <>{price.min} - {price.max}</>;
    }
    if (obj.value) {
      return obj.value;
    }
    return '';
  };

  return (
    <GridItem
      key={name}
      behavior="no-shrink"
      className="srpGReach__list__item c-bg--w b--s is-rel"
    >
      <div className="p--m">
        <p className="srpGReach__list__item__tit tp-s--l tp-w--l c-txt--f0">
          <a
            href={uri}
            title={`${main} in ${street} a ${city} (${province})`}
            className="c-txt--f0"
            onClick={trackClick}
          >
            Nuova costruzione in {street} a {city} ({province})
          </a>
        </p>
        <p className="srpGReach__list__item__desc tp-s--m tp-w--s c-txt--f5">{description}</p>
      </div>
      <div className="srpGReach__list__item__imgcont c-bg--bg">
        {visible && <img className="srpGReach__list__item__img" src={`https://images-1.casa.it/550x314${photo}`} alt={`${main} in ${street} a ${city} (${province})`} />}
      </div>
      <Grid className="srpGReach__list__item__infos">
        <GridItem
          taghtml="a"
          className="srpGReach__list__item__pub tp-s--s tp-w--s c-txt--f0 is-rel"
          behavior="fixed"
          href={publisherUrl}
          title={`Vedi tutti gli annunci dell'agenzia ${publisherName}`}
        >
          {logoImg}
        </GridItem>
        <GridItem className="srpGReach__list__item__feats">
          <p className="srpGReach__list__item__feats__line price tp-s--m">{price.show ? <>&euro;&nbsp;<span className="tp-s--l tp-w--l">{getPriceVal(price)}</span></> : 'Trattativa riservata'}</p>
          <p className="srpGReach__list__item__feats__line items tp-s--m">
            {size && <span>{size}mq</span>}
            {rooms && <span>{rooms} locali</span>}
          </p>
        </GridItem>
        <GridItem
          className="srpGReach__list__item__cta"
          behavior="fixed"
        >
          <span className="tp-s--s tp-w--m tp-a--c b--s">
            Vedi nuova residenza
          </span>
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default NewGeoReachCard;

NewGeoReachCard.propTypes = {
  project: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  index: PropTypes.number,
};

NewGeoReachCard.defaultProps = {
  project: null,
  visible: false,
  index: 0,
};
