// @flow
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppRoot from '../search/SearchPage';
import * as searchActions from '../../common/redux/search/actions';
import * as homeActions from '../../common/redux/home/actions';

function mapStateToProps(state) {
  return {
    ...state,
  };
}

// we can merge multiple actions
const actions = [
  searchActions,
  homeActions,
];

function mapDispatchToProps(dispatch) {
  const getActionFunctions = (act: Array<Object>) => {
    let resultObj = {};
    act.map((action) => {
      Object.keys(action).map((key) => {
        if (typeof action[key] === 'function') {
          resultObj = Object.assign({}, resultObj, { [key]: action[key] });
        }
        return key;
      });
      // for (const key in action) {
      //   if (typeof action[key] === 'function') {
      //     resultObj = Object.assign({}, resultObj, { [key]: action[key] });
      //   }
      // }
      return action;
    });

    return resultObj;
  };

  const creators = getActionFunctions(actions);

  return {
    actions: bindActionCreators(creators, dispatch),
    dispatch,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRoot));
