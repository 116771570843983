import React from 'react';
import PropTypes from 'prop-types';

import {
  getFilterLists,
  getActiveFilters,
} from '@filters/helpers';

import { CtxContainer } from '@client/app/Container';

import {
  showLicenceTypeGroupsButton,
  // showLicenceContractTypeGroupsButton,
} from '../../../helpers/search/Search';

import FallbackLoader from '../../../loaders/components/FallbackLoader';
import Skeleton from './skeleton';

class FiltersSkeleton extends React.Component {
  static contextType = CtxContainer;

  constructor(props) {
    super(props);

    this.state = {
      component: null,
      skeleton: null,
    };
  }

  componentWillMount() {
    const {
      globalSelector,
      model: {
        clientFilters: {
          skBox1,
          skBox2,
          skBox3,
          skBox4,
          skBox5,
        },
      },
      filtersProps: {
        isPublisherHP,
        isPublisherPage,
        isAgencySrp,
        filters,
        locations,
        parentLocation,
        localitiesWithAgencyZones,
        startWithSelectedPtypes,
        propertyTypesGroup,
      },
    } = this.context;

    const onePTypeSelectCategories = [
      'stanze',
      'cantine-solai',
      'garage-posti-auto',
      'nuove-costruzioni',
    ];

    const hasPois = globalSelector('availablePoi').length > 0;

    let locationHasChildren = true;    
    if (
      (
        locations.length === 1
        && !locations[0].has_children
        && (!parentLocation || !Object.keys(parentLocation).length)
      ) || locations.length === 0
      || (
        isAgencySrp
        && locations.length === 1
        && !localitiesWithAgencyZones.includes(locations[0].slug)
      )
    ) {
      locationHasChildren = false;
    }

    const { hiddenFilters } = getFilterLists(4, null);    
    const { activeFiltersList } = getActiveFilters(filters);
    const hasHiddenFilter = Object.keys(activeFiltersList).some(item => hiddenFilters.includes(item));
    const open = hasHiddenFilter;

    let onePTypeSelect = startWithSelectedPtypes;
    if (activeFiltersList.category && onePTypeSelectCategories.indexOf(activeFiltersList.category) > -1) {
      onePTypeSelect = true;
    }

    const skeleton = (
      <Skeleton
        open={open}
        isPublisherPage={isPublisherPage}
        isAgencySrp={isAgencySrp}
        showPTypesButton={!isPublisherHP && !onePTypeSelect && !isAgencySrp && !(isPublisherPage && !activeFiltersList.category)}
        showLicenceTypeGroupsButton={showLicenceTypeGroupsButton(activeFiltersList)}        
        // showLicenceContractTypeGroupsButton={showLicenceContractTypeGroupsButton(filters)}
        hasPois={hasPois}
        locationHasChildren={locationHasChildren}
        skBox1={skBox1}
        skBox2={skBox2}
        skBox3={skBox3}
        skBox4={skBox4}
        skBox5={skBox5}
      />
    );

    const component = FallbackLoader(() => import(/* webpackChunkName: "async-filterSection" */'@components/common/FilterSection'), {
      fallback: skeleton,
    });

    this.setState({
      component,
      skeleton,
    });
  }

  render() {    
    const { showFilters } = this.props;
    const { component, skeleton } = this.state;
    const { filtersProps: { device, orientation } } = this.context;
    
    const Component = component;
    
    const fallBack = !device || device === 'desktop'
      ? skeleton
      : null;

    if (device === 'smartphone' || (device === 'tablet' && orientation === 'portrait')) {
      return null;
    }

    return showFilters
      ? <Component inModal={false} />
      : fallBack;
    // return fallBack;
  }
}

export default FiltersSkeleton;

FiltersSkeleton.propTypes = {
  showFilters: PropTypes.bool,
};

FiltersSkeleton.defaultProps = {
  showFilters: false,
};
