import React from 'react';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents';
import openGenericModal from 'shared-utils/src/leads/openGenericModal';

import Icon from '@components/common/Icon';
import { useCtxGlobal } from '@client/app/Container';
import { getHumanFilters, getPtypesPlurals } from '@helpers/search/Search';
import { getPreviewFromFilters } from '@filters/helpers';

import { createGenericModal, createFeedbackModal } from '../../../modals/factories';

const RizBanner = () => {
  const {
    openModal,
    closeModal,
    updateUserFormData,
    filtersProps: {
      device,
      mainLocation,
      additionalLocations,
      filters,
      getActiveFilters,
    },
    user,
  } = useCtxGlobal();

  const open = () => {
    trackGTMEvents('Interaction', 'PushButton', 'ShowContactAgenciesFormSRP');

    const closeModalAction = payload => closeModal(payload.close);

    const leadConf = {
      device,
      mainLocation,
      additionalLocations,
      filters,
      getActiveFilters,
      getHumanFilters,
      getPtypesPlurals,
      getPreviewFromFilters,
      user,
    };

    openGenericModal({
      openModalAction: openModal,
      closeModalAction,
      createGenericModal,
      createFeedbackModal,
      setUserFormDataAction: updateUserFormData,
      leadConf,
    });
  };

  return (
    <button
      type="button"
      aria-label="Contatta le agenzie in zona"
      className="b--0 c-bg--w tp-s--s tp-w--s tp-a--l c-txt--f2 is-clickable is-rel"
      onClick={open}
    >
      Hai bisogno di aiuto per la ricerca?
      <span className="tp-w--m c-txt--secondary tp--u">Chiedi alle agenzie</span>
      <Icon className="icon-riz" name="riz" width="24px" height="21px" />
    </button>
  );
};

export default RizBanner;
