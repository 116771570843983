import React from 'react';
import PropTypes from 'prop-types';

import { SORT_VALUES, SORT_DEFAULT_LABEL } from 'shared-constants/src/filters';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import { useCtxGlobal } from '@client/app/Container';
import Icon from '@components/common/Icon';

import {
  isDrawonMapSearch,
} from '../../../helpers/search/Search';

import TopButtons from '../TopButtons';
import ProposeBaloon from '../ProposeBaloon/index';
import HeaderTags from '../HeaderTags';

const Menu = ({
  viewport,
  siteUrl,
  accountUrl,
  authBasePrefix,
  mapUrl,
  mapLinkAvailable,
  apiReqMyCasaBKM,
  isLocalStorageAvailable,
  userLogIn,
  afterClose,
  setForcedAuction,
  checkProposeBkmAfterLead,
  proposeBkmBaloon,
  searchIsSaved,
}) => {
  const {
    model: { isAgencySrp, isPublisherHP, publisher, total, uri, fewResults },
    user,
    filtersProps: {
      device,
      orientation,
      filters,
      locations,
      mainLocation,
      additionalLocations,
      setFilters,
    },
  } = useCtxGlobal();


  const showTB = !isPublisherHP;
  const hideTbOnDesktop = total === 0 && !isDrawonMapSearch(locations, filters);
  const sortValuesMap = SORT_VALUES[isAgencySrp ? 'agencies' : 'realEstate'];
  const showProposeBkmBaloon = !isAgencySrp && !isPublisherHP && proposeBkmBaloon && !searchIsSaved;

  const sortWrapperCondition =
    ((device !== 'desktop' &&
      !(device === 'tablet' && orientation === 'landscape')) ||
      (device === 'desktop' && viewport.width < 1024)) &&
    (total > 0 || (fewResults && fewResults.page > 1)) &&
    !isPublisherHP;

  const onSortChange = (value) => {
    sleep(300).then(() => trackGTMEvents(
      {
        category: 'Interaction',
        action: 'ChangeSortOrder',
      }, {
        sortOrder: `from${filters.sortType}-to${value}`,
      },
      true,
    ));
    setFilters({ sortType: value });
  };
  const onSortSelectChange = e => onSortChange(e.target.value);

  const sortComponent = (
    <div className="srp-sort">
      <div className="srp-sort__fakeSelect">
        <span className="srp-sort__icon-cont">
          <Icon name="sort" size="free" />
        </span>
        <span className="srp-sort__label">
          {filters.sortType === Object.keys(sortValuesMap)[0]
            ? SORT_DEFAULT_LABEL
            : (sortValuesMap[filters.sortType] || sortValuesMap.default)}
        </span>
      </div>
      <select
        className="srp-sort__select"
        name="sort"
        dir="rtl"
        value={filters.sortType}
        onChange={onSortSelectChange}
      >
        {Object.keys(sortValuesMap).map(item => (
          <option key={item} value={item}>
            {sortValuesMap[item]}
          </option>
        ))}
      </select>
    </div>
  );

  if (showTB || sortWrapperCondition) {
    return (
      <>
        {showTB && (
          <div
            className={`srp-topbtns-cont${
              hideTbOnDesktop ? ' hideOnDesktop' : ''
            }`}
          >
            <TopButtons
              device={device}
              orientation={orientation}
              mapUrl={mapUrl}
              mapLinkAvailable={mapLinkAvailable}
              user={user}
              userLogIn={userLogIn}
              locations={locations}
              uiLocations={{ mainLocation, additionalLocations }}
              filters={filters}
              apiReqMyCasaBKM={apiReqMyCasaBKM}
              isLocalStorageAvailable={isLocalStorageAvailable}
              siteUrl={siteUrl}
              currentUrl={uri}
              accountUrl={accountUrl}
              authBasePrefix={authBasePrefix}
              sortType={filters.sortType}
              sortChange={onSortChange}
              sortValuesMap={sortValuesMap}
              publisherName={
                publisher && publisher.publisherName
                  ? publisher.publisherName
                  : null
              }
              afterClose={afterClose}
              setForcedAuction={setForcedAuction}
              checkProposeBkmAfterLead={checkProposeBkmAfterLead}
              viewport={viewport}
            />
          </div>
        )}
        <HeaderTags
          filters={filters}
          updateFilters={setFilters}
          forceMobileUpdate
        />
        {sortWrapperCondition && sortComponent}
        {showProposeBkmBaloon && device !== 'desktop' && (
          <ProposeBaloon
            baloonType={showProposeBkmBaloon.value}
            baloonFrom={showProposeBkmBaloon.from}
            publisherName={
              publisher && 'name' in publisher ? publisher.name : null
            }
            setForcedAuction={setForcedAuction}
            checkProposeBkmAfterLead={checkProposeBkmAfterLead}
            userLogIn={userLogIn}
          />
        )}
      </>
    );
  }
  return null;
};

export default Menu;

Menu.propTypes = {
  viewport: PropTypes.instanceOf(Object),
  siteUrl: PropTypes.string,
  accountUrl: PropTypes.string,
  authBasePrefix: PropTypes.string,
  mapUrl: PropTypes.string,
  mapLinkAvailable: PropTypes.bool,
  apiReqMyCasaBKM: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
  userLogIn: PropTypes.func,
  afterClose: PropTypes.func,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  proposeBkmBaloon: PropTypes.instanceOf(Object),
  searchIsSaved: PropTypes.bool,
};

Menu.defaultProps = {
  viewport: {},
  siteUrl: '',
  accountUrl: '',
  authBasePrefix: '',
  mapUrl: '',
  mapLinkAvailable: false,
  apiReqMyCasaBKM: {},
  isLocalStorageAvailable: false,
  userLogIn: () => {},
  afterClose: () => {},
  setForcedAuction: () => {},
  checkProposeBkmAfterLead: () => {},
  proposeBkmBaloon: null,
  searchIsSaved: false,
};
