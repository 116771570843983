import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const humanPtypes = {
  Appartamento: 'Agli appartamenti',
  'Attico/Mansarda': 'Agli attici e alle mansarde',
  'Baita/Chalet/Trullo': 'Alle baite, agli chalet e ai trulli',
  Bungalow: 'Ai bungalow',
  'Cantina/Solaio': 'Alle cantine e ai solai',
  'Casa Bi/Trifamiliare': 'Alle case bi/trifamiliari',
  'Casa indipendente': 'Alle case indipendenti',
  'Garage/Box auto': 'Ai garage e ai box',
  Loft: 'Ai loft',
  Palazzo: 'Ai palazzi',
  'Rustico/Casale': 'Ai rustici e ai casali',
  Stanza: 'Alle stanze',
  Terreno: 'Ai terreni',
  'Terreno edificabile': 'Ai terreni edificabili',
  Villa: 'Alle ville',
  'Villetta a schiera': 'Alle villette a schiera',
  Albergo: 'Agli alberghi',
  'Attività/Licenza comm': 'Alle attività e alle licenze commerciali',
  'Azienda agricola': 'Alle aziende agricole',
  Bar: 'Ai bar',
  Capannone: 'Ai capannoni',
  'Cessione di azienda': 'Alle cessioni di azienda',
  'Garage/Auto silos': 'Ai garage e agli auto silos',
  Laboratorio: 'Ai laboratori',
  'Locale commerciale': 'Ai locali commerciali',
  Magazzino: 'Ai magazzini',
  Resort: 'Ai resort',
  Ristorante: 'Ai ristoranti',
  'Show room': 'Agli show room',
  'Stabile/Palazzo': 'Agli stabili e ai palazzi',
  'Terreno agricolo': 'Ai terreni agricoli',
  Ufficio: 'Agli uffici',
  'Ufficio di rappresentanza': 'Agli uffici di rappresentanza',
};

const SingleBoxPropTypes = {
  title: PropTypes.string,
  linkList: PropTypes.instanceOf(Array),
};
const SingleBoxDefaultProps = {
  title: '',
  linkList: [],
};
const SingleBox = ({
  title,
  linkList,
}) => {
  const cls = [
    'seoBoxes--box',
    'pt--m',
    'pl--m',
    'pr--m',
  ].filter(i => i).join(' ');
  return (
    <div className={cls}>
      <p className="seoBoxes--box__tit tp-s--s tp-w--m">{title}</p>
      <ul className="seoBoxes--box__list">
        {linkList}
      </ul>
    </div>
  );
};
SingleBox.propTypes = SingleBoxPropTypes;
SingleBox.defaultProps = SingleBoxDefaultProps;

const SEOLinksBoxPropTypes = {
  locations: PropTypes.instanceOf(Object),
  ptypes: PropTypes.instanceOf(Array),
  SEOLinks: PropTypes.instanceOf(Object),
  numRoomsRange: PropTypes.instanceOf(Object),
  channel: PropTypes.string,
};
const SEOLinksBoxDefaultProps = {
  locations: {},
  ptypes: [],
  SEOLinks: {},
  numRoomsRange: {},
  channel: '',
};
const SEOLinksBox = ({
  locations,
  ptypes,
  SEOLinks,
  channel,
  numRoomsRange,
  totalPages,
  isPublisherHp,
  fewResults,
}) => {
  let usableSEOLinks = SEOLinks;
  let seoImprovement = false;
  if (
    numRoomsRange.min !== 'all'
    && numRoomsRange.max !== 'all'
    && numRoomsRange.min === numRoomsRange.max
    && parseInt(numRoomsRange.min, 10) > 0
    && parseInt(numRoomsRange.min, 10) < 5
  ) {
    seoImprovement = true;
  }
  if (seoImprovement) {
    if ('type' in SEOLinks) {
      delete SEOLinks.type;
    }
 
    usableSEOLinks = {
      ...SEOLinks,
    };
  }

  const typeTitle = () => {
    const location = locations[0] || {};
    if (location.type === 'town') {
      return `Altri immobili a ${location.name}:`;
    }
    if (location.type === 'province') {
      return `Altri immobili in provincia di ${location.name}:`;
    }
    if (location.type === 'zone') {
      return `Altri immobili in zona ${location.name} a ${location.parent.town.name}:`;
    }
    return 'Cerca per tipologia';
  };
  const shouldUsePType = ptypes.length === 1 && ptypes[0] in humanPtypes ? humanPtypes[ptypes[0]] : 'Agli immobili';
  const boxConf = {
    type: {
      title: typeTitle(),
      priority: 100,
    },
    region: {
      title: locations.length === 0 ? 'Cerca per regione' : 'Immobili in altre regioni',
      priority: 90,
    },
    province: {
      title: 'Immobili in altre province',
      priority: 80,
    },
    city: {
      title: 'Cerca per comune',
      priority: 70,
    },
    zone: {
      title: 'Cerca per zona',
      priority: 60,
    },
    street: {
      title: 'Cerca per indirizzo',
      priority: 50,
    },
    feature: {
      title: `${shouldUsePType} aggiungi:`,
      priority: 40,
    },
    feature_remove: {
      title: `${shouldUsePType} togli:`,
      priority: 30,
    },
    seller: {
      title: 'Cerca per tipologia inserzionista',
      priority: 20,
    },
    more: {
      title: 'Cerca anche',
      priority: 10,
    },
    metrolines: {
      title: 'Cerca vicino alle linee della metropolitana',
      priority: 10,
    },
    metrostops: {
      title: 'Cerca vicino alle fermate della metropolitana',
      priority: 10,
    },
    license_type_groups: {
      title: 'Cerca per tipo di attività',
      priority: 10,
    },
  };
  const haveNoContents = x => (
    !x
    || Object.keys(x).length === 0
    || Object.keys(x).filter(el => x[el] && x[el].length !== 0).length === 0
  );

  const createSEOLinks = () => {
    if (haveNoContents(usableSEOLinks)) {
      return null;
    }
    const boxes = {};
    Object.keys(usableSEOLinks).forEach(
      (linkGroup) => {
        const links = usableSEOLinks[linkGroup].map(
          (el, index) => {
            const n = index;
            const title = el.linkTitle !== '' ? el.linkTitle : el.linkText;
            return (
              <li
                key={`seoLink_${linkGroup}_${n}`}
                className="seoBoxes--box__lnk tp-s--m tp-w--m tp--ell"
              >
                <a className="c-txt--f1" href={el.linkHref} title={title}>
                  {el.linkText}
                </a>
              </li>
            );
          },
        );
        boxes[linkGroup] = links.length > 0 ? (
          <SingleBox
            key={`seobox_${linkGroup}`}
            title={boxConf[linkGroup].title}
            linkList={links}
            priority={boxConf[linkGroup].priority}
          />
        ) : null;
      },
    );
    return boxes;
  };
  const boxes = createSEOLinks();
  if (boxes && Object.keys(boxes).length > 0) {
    return (
      <div className="seoBoxes">
        {
          boxes
          &&
          Object.keys(boxes).map(
            box => boxes[box],
          ).filter(Boolean).sort((a, b) => a.props.priority > b.props.priority ? -1 : 1)
        }
      </div>
    );
  }
  return !isPublisherHp && totalPages <= 1 && !fewResults
    ? <div className="mt--l" />
    : null;
};
SEOLinksBox.propTypes = SEOLinksBoxPropTypes;
SEOLinksBox.defaultProps = SEOLinksBoxDefaultProps;
export default SEOLinksBox;
