import React from 'react';

import Grid from 'shared-components/components/Grid';
import WatchScroll from 'shared-components/components/WatchScroll';
import LazyLoad from 'shared-components/components/LazyLoad';

import { CtxContainer } from '@client/app/Container';

import Card from '@components/searchPage/results/Card';
import AgencySrpCard from '@components/searchPage/results/AgencySrpCard/index';
import RecentAdsTitle from '@components/searchPage/RecentAdsTitle/index';
import RecentAdsLimiter from '@components/searchPage/RecentAdsLimiter/index';
import SortTypeLink from '@components/searchPage/SortTypeLink';
import NewGeoReach from '../components/searchPage/results/NewGeoReach';
import OptSaveSearchBanner from '../components/searchPage/OptSaveSearchBanner';
import ValutaTuBanner from '../components/searchPage/ValutaTuBanner/index';

class ResultList extends React.PureComponent {
  static contextType = CtxContainer;

  render() {    
    const {
      isMobile,
      isSmallMobile,
      iosLth12,
      globalSelector,
      globalReducer,
      globalState: {
        favorites,
        config: {
          cookiesConfig = {},
          api: {
            account,
            authBasePrefix,
          },
        } = {},
      } = {},
      model: {
        total,
        isPublisherHp,
        isPublisherPage,
      },
      user,
      logIn,
      showSynchingDialog,
      openModal,
      closeModal,
      siteUrl,
      filtersProps,
      filtersProps: {
        device,
        locations,
      },
      userConf: {
        userUrls: {
          userhost,
        },
      },
      pageType,
      setNewSearchFromSaveSearchModal,
      getUser,
      getUserLoginPayload,
      updateUserFormData,
      saveBookmarkSuccess,
    } = this.context;

    const {
      resultsList,
      search,
      compactMandateBanner,
      setSaveSearchBannerVisibile,
      checkProposeBkmAfterLead,
      setValutaTuBannerVisible,
      saveSearchBannerVisible,
      isLocalStorageAvailable,
      mobileOs,
      setForcedAuction,
      valutaTuVisible,
      loadDetail,
      viewport,
      fillInExpanded,
      env,
      filters,
      setCurrentFloorplan,
      searchIsSaved,
    } = this.props;

    const cardProps = {
      isMobile,
      isSmallMobile,
      iosLth12,
      globalSelector,
      globalReducer,
      cookiesConfig,
      account,
      authBasePrefix,
      total,
      isPublisherHp,
      isPublisherPage,
      logIn,
      showSynchingDialog,
      openModal,
      closeModal,
      siteUrl,
      filtersProps,
      device,
      filters,
      locations,
      userhost,
      pageType,
      setNewSearchFromSaveSearchModal,
      getUser,
      getUserLoginPayload,
      updateUserFormData,
      saveBookmarkSuccess,
      favorites,
      user,
      searchIsSaved,
    };

    let articleCount = 0;

    return resultsList.map((article, indexArticle) => {
      if (!search.isAgencySrp) {
        if (typeof article === 'string') {
          if (article.indexOf('georeach_') > -1) {
            return (
              <LazyLoad key={`geoReach_page_${article}`}>
                {
                  (visible, setRef) => (
                    <NewGeoReach
                      list={search.georeachList}
                      visible={visible}
                      setRef={setRef}
                    />
                  )
                }
              </LazyLoad>
            );
          }
          if (article === 'stripmobile') {
            return (
              <Grid
                key={`listing_${article}`}
                direction="column"
                align="center"
                className="newstrip stripmobile is-rel"
              >
                <div id={article} />
              </Grid>
            );
          }
          if (article === 'forcedAuction') {
            return (
              <div key={article} className="forcedAuction mt--m mb--m tp-s--l tp-w--s tp-a--c">
                <span className="forcedAuction__lb">Abbiamo aggiornato la tua ricerca</span> attivando il filtro <span className="tp-w--l">escludi aste</span>
              </div>
            );
          }
          return (
            <Grid
              id={article === 'strip1' ? null : article}
              key={`listing_${article}`}
              direction="column"
              align="center"
              className={`newstrip is-rel ${article === 'strip1' ? article : ''}`}
            >
              {article === 'strip1' ? <div id={article} /> : null}
            </Grid>
          );
        }

        articleCount += 1;

        const isSixth = articleCount === 6;
        const addValutaTuBanner = (articleCount === 7 && mobileOs === 'android');
        const addSaveSearchBanner = (articleCount === 15) && !searchIsSaved;
        const addSaveSearchBannerId = `saveSearchBanner_${articleCount}`;
        const addValutaTuBannerId = `valutaTuBanner_${articleCount}`;

        return (
          <div key={`article_${article.id}_${filters.firstMedia}_${articleCount}`}>
            {
              article.recentAdsTitle
                ? indexArticle === 0
                  ? <RecentAdsTitle title={article.recentAdsTitle} />
                  : <RecentAdsLimiter title={article.recentAdsTitle} />
                : null
            }
            <Card
              article={article}
              isSixth={isSixth}
              isFetching={search.isFetching}
              loadDetail={loadDetail}
              setCurrentFloorplan={setCurrentFloorplan}
              collapseMandateBanner={compactMandateBanner}
              viewport={viewport}
              {...cardProps}
            />
            {articleCount === search.list.length && filters.sortType === 'relevance' && !search.isPublisherHP
              ? (
                <SortTypeLink
                  hide={fillInExpanded}
                  listingEnd={!search.fewResults}
                  env={env}
                />
              ) : null
            }
            {addSaveSearchBanner && (
              <WatchScroll stopWatching={saveSearchBannerVisible}>
                {scrollInfos => (
                  <OptSaveSearchBanner
                    id={addSaveSearchBannerId}
                    scrollInfos={scrollInfos}
                    setSaveSearchBannerVisibile={setSaveSearchBannerVisibile}
                    setForcedAuction={setForcedAuction}
                    checkProposeBkmAfterLead={checkProposeBkmAfterLead}
                  />
                )}
              </WatchScroll>
            )}
            {(addValutaTuBanner && search.currentPage === 1) && (
              <WatchScroll stopWatching={valutaTuVisible}>
                {scrollInfos => (
                  <ValutaTuBanner
                    id={addValutaTuBannerId}
                    scrollInfos={scrollInfos}
                    setValutaTuBannerVisible={setValutaTuBannerVisible}
                    currentPage={search.currentPage}
                  />
                )}
              </WatchScroll>
            )}                  
          </div>
        );
      }
      articleCount += 1;
      const lastOne = articleCount === resultsList.length;

      if (typeof article !== 'string') {
        return (
          <AgencySrpCard
            key={`pcard_${article.id}`}
            device={device}
            data={article}
            lastOne={lastOne}
            user={user}
            viewport={viewport}
            isLocalStorageAvailable={isLocalStorageAvailable}
          />
        );
      }
    });
  }
}

export default ResultList;
