import React from 'react';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

const MandateBanner = () => {
  const action = () => {
    trackGTMEvents({
      category: 'Valuation',
      action: 'GoToPropertyValuation',
      position: 'SRP_SidebarBox',
    });
    document.location = '/valutazione-immobile/';
  };
  return (
    <button
      type="button"
      aria-label="Scopri quanto vale la tua casa"
      className="srp-sb-banner--mandate b--0 c-bg--w tp-s--s tp-w--s tp-a--l c-txt--f2 is-clickable"
      onClick={action}
    >
      <strong className="tp-s--m">Valuta la tua casa</strong>
      Sai quanto vale la tua casa?
      <span className="tp-w--m c-txt--secondary tp--u">Scoprilo subito, è gratis!</span>
    </button>
  );
};

export default MandateBanner;
