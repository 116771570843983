import React, {
  useState,
  useRef,
  useEffect,
  memo,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'shared-components/components/Button';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Check from 'shared-vectors/icons/Check';
import Phone from 'shared-vectors/icons/Phone';
import Mail from 'shared-vectors/icons/Mail';
import { Icon } from 'shared-vectors';
import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import trackTLEvents from 'shared-utils/src/trackTLEvents';
import sleep from 'shared-utils/src/sleep';
import openSpecificModal from 'shared-utils/src/leads/openSpecificModal';
import formatPublisherAddress from 'shared-utils/src/leads/publisherAddress';
import CallAgencyTooltipContainer from 'shared-components/components/QRCodeTooltipFactory/CallAgencyTooltipContainer';
import Description from 'shared-components/components/SrpCard/components/Details/components/Description';
import deleteTealiumExtraData from 'shared-utils/src/deleteTealiumExtraData';

import { post } from '@helpers/http';
import { useCtxGlobal } from '@client/app/Container';
import { createQRCodeOnly } from '@client/components/common/DynamicLoader/qrcode';

import Agents from '../../Agents';

import {
  createSpecificModal,
  createFeedbackModal,
} from '../../../modals/factories';

import './style.scss';

const propTypes = {
  device: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  lastOne: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  viewport: PropTypes.instanceOf(Object),
};

const defaultProps = {
  device: '',
  data: {},
  lastOne: false,
  user: {},
  viewport: {},
};

const AgencySrpCard = ({
  device,
  data,
  lastOne,
  user,
  viewport,
}) => {
  const trackPosition = 'SRPA_Card';
  const {
    publisherId,
    publisherName,
    publisherPhone,
    publisherSlug,
    showPhoneNumber,
    publisherAddress,
    description,
    listingCounter,
    logoSrc,
    publisherUrl,
    hasPrimavisita,
    hasMandate,
    hasCrossSubscription,
    hasAgents,
    agents,
  } = data;

  const {
    openModal,
    closeModal,
    isMobile,
    updateUserFormData,
    siteUrl,
  } = useCtxGlobal();

  const [phoneReveal, setPhoneReveal] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const hideTooltip = () => setTooltipVisible(false);
  const descEl = useRef(null);

  const baseCls = 'agencySRPCard';
  const mainCls = makeClassName([
    baseCls,
    'c-bg--w',
    'mt--s',
    lastOne ? 'mb--l' : '',
    'pr--m pl--m pb--m pt--m',
  ]);


  const call = async () => {
    const label = `Agency - ${publisherId}`;
    sleep(300).then(() => trackGTMEvents({
      category: 'Lead',
      action: 'CallPhone',
      position: 'SRPA_Card',
      label,
    }, null, true));

    const productType = 'agency';

    const evMore = {
      contact: {
        channel: 'phone',
        productType,
        outcome: '',
        errorField: ['', '', ''],
      },
    };

    if (publisherSlug) {
      evMore.agency = {
        name: [publisherSlug],
      };
    }
    trackTLEvents({
      evObj: {
        event: {
          type: 'view',
          name: 'contact',
          origin: '',
        },
      },
      evMore,
    });

    if (hasCrossSubscription) {
      const proxyBaseUrl = '/portal-srp/api/v1/phonecall';
      post(proxyBaseUrl, {
        data: {
          publisherId,
        },
      });
    }
    deleteTealiumExtraData({ keysToDelete: ['agency'] });
    window.location.href = `tel:${publisherPhone}`;
  };

  const phoneClick = ({ fromModal = false } = {}) => {
    if (!fromModal && !phoneReveal && !isMobile) {
      sleep(300).then(() => trackGTMEvents({
        category: 'Lead',
        action: 'RevealPhone',
        position: trackPosition,
      }, null, true));
    }
    if (device !== 'smartphone') {
      setPhoneReveal(true);
      setTooltipVisible(true);
      if (phoneReveal) {
        if (!calling) {
          setCalling(true);
          setTimeout(() => {
            setCalling(false);
          }, 500);
          call();
        }
      }
    } else if (!calling) {
      setCalling(true);
      setTimeout(() => {
        setCalling(false);
      }, 750);
      call();
    }
  };


  const modalCallAction = async (payload) => {
    await closeModal(payload.closeModal);
    setTimeout(() => phoneClick({ isFromModal: true }), 301);
  };

  const contactModal = async () => {
    sleep(300).then(() => trackGTMEvents({
      category: 'Interaction',
      action: 'OpenContactForm',
      position: trackPosition,
    }));

    const closeModalAction = payload => closeModal(payload.close);

    openSpecificModal({
      openModalAction: openModal,
      closeModalAction,
      createSpecificModal,
      createFeedbackModal,
      setUserFormDataAction: updateUserFormData,
      siteUrl,
      leadConf: {
        user,
        isMobile,
        device,
        app: 'P18',
        isPublisherLead: true,
        publisher: data,
        justEmail: true,
        startCall: modalCallAction,
        isPublisherPage: false,
      },
    });
  };

  let telephone = null;
  if (publisherPhone) {
    telephone = phoneReveal
      ? publisherPhone
      : `${publisherPhone.substr(0, 4)}...`;
  }

  const hasBuyAds = listingCounter.buy > 0;
  const hasRentAds = listingCounter.rent > 0;
  const hasPlchldr = logoSrc.indexOf('/agencylogo') > -1;

  const ctaTxt = hasBuyAds || hasRentAds
    ? 'Vedi immobili'
    : 'Vedi dettagli agenzia';

  return (
    <div
      ref={descEl}
    >
      <Grid
        direction="column"
        className={mainCls}

      >
        <Grid
          className={`${baseCls}__top`}
          direction={isMobile ? 'column' : 'row'}
          justify={isMobile ? 'center' : 'space-between'}
        >
          <div className={`${baseCls}__top--infos`}>
            <h2 className={`${baseCls}__top--name tp-s--l tp-w--l c-txt--f0`}>
              {publisherName}
            </h2>
            <p className={`${baseCls}__top--addr tp-w--s c-txt--f5`}>{formatPublisherAddress(publisherAddress)}</p>
          </div>
          {
            !hasPlchldr
              ? (
                <GridItem
                  behavior="fixed"
                  taghtml="a"
                  className={`${baseCls}__top--logo${hasCrossSubscription ? ' logopm' : ''}`}
                  href={publisherUrl}
                  title={`Vedi tutti gli immobili dell'agenzia ${publisherName}`}
                >
                  <img src={logoSrc} alt={publisherName} loading="lazy" />
                </GridItem>
              ) : null
          }
        </Grid>

        <Grid
          className={`${baseCls}__products mt--ml`}
          direction="column"
        >
          <Grid
            direction={isMobile ? 'column' : 'row'}
          // justify={isMobile ? null : 'space-between'}
          >
            <Grid direction={isMobile ? 'column' : 'row'}>
              <div className={`${baseCls}__products__ads`}>
                <p className={`${baseCls}__products__ads--p c-txt--f0 tp-w--s tp-s--l pl--l pr--l`}>
                  <strong className={`${baseCls}__products__ads--b tp-w--l`}>{hasBuyAds ? listingCounter.buy : '- -'}</strong> in vendita
                </p>
                <p className={`${baseCls}__products__ads--p c-txt--f0 tp-s--l tp-w--s pl--l pr--l`}>
                  <strong className={`${baseCls}__products__ads--b tp-w--l`}>{hasRentAds ? listingCounter.rent : '- -'}</strong> in affitto
                </p>

              </div>
              {
                (hasPrimavisita || hasMandate)
                && (
                  <Grid
                    className={`${baseCls}__products__mktg`}
                    direction={isMobile ? 'column' : null}
                    justify={isMobile ? null : 'space-between'}
                  >
                    {
                      hasPrimavisita
                      && (
                        <p className={`${baseCls}__products__mktg--p c-txt--f0 tp-w--m`}>
                          <Icon name={Check} width="10px" height="10px" color="w" />
                          <strong className="tp-w--l">
                            Prima
                            <span className="c-txt--primary">Visita</span>
                          </strong>
                          &nbsp;guidata da casa
                        </p>
                      )
                    }
                    {
                      hasMandate
                      && (
                        <p className={`${baseCls}__products__mktg--p c-txt--f0 tp-w--m ${isMobile ? 'mt--m' : ''}`}>
                          <Icon name={Check} width="10px" height="10px" color="w" /> Valutazione immobile
                        </p>
                      )
                    }
                  </Grid>
                )
              }
            </Grid>
            {
              hasAgents
              && (
                <Grid className={`${baseCls}__agents-cnt`}>
                  <Agents
                    list={agents.list}
                    total={agents.total}
                    link={`${publisherUrl}agenti/`}
                    publisherName={publisherName}
                    className={`${baseCls}__products__agents`}
                    direction="row"
                  />
                </Grid>
              )
            }
          </Grid>

        </Grid>
        {description ?
          <div className={isMobile ? 'mb--m' : 'mt--ml mb--s'}>
            <Description
              details={descEl}
              description={description}
              vWidth={viewport.width}
              depthInfos={{}}
            />
          </div>
          : <GridItem className="mb--s" />}
        <Grid className={`${baseCls}__btns`}>
          <GridItem className={`${baseCls}__btns__link`}>
            <Button
              className={`${baseCls}__btns__link--btn no-link tp-w--l`}
              action={publisherUrl}
              title={`Vedi tutti gli immobili dell'agenzia ${publisherName}`}
            >
              {ctaTxt}
            </Button>
          </GridItem>
          <Grid className={`${baseCls}__btns__cts`}>
            {
              publisherPhone && showPhoneNumber
              && (
                <>
                  <Button
                    border="f2-s"
                    color={isMobile ? 'f0' : 'f5'}
                    bg="0"
                    className={`${baseCls}__btns__cts--btn`}
                    data-action="phonecall"
                    action={phoneClick}
                  >
                    <Icon name={Phone} color={isMobile ? 'f0' : 'f5'} width="12px" height="17px" className={`${baseCls}__btns__cts--btn--ph`} /> {
                      device !== ''
                        && device !== 'smartphone'
                        ? telephone
                        : 'Chiama'
                    }

                  </Button>
                  {phoneReveal &&
                    <CallAgencyTooltipContainer
                      showDynamicComponent={phoneReveal}
                      createQRCodeOnly={createQRCodeOnly}
                      visible={tooltipVisible}
                      setTooltipVisible={hideTooltip}
                      phone={telephone}
                      clsPositionToolip="--agency-srp"
                      serverUri={siteUrl}
                    />
                  }
                </>
              )
            }
            <Button
              border="f2-s"
              color={isMobile ? 'f0' : 'f5'}
              bg="0"
              className={`${baseCls}__btns__cts--btn modal_handle no-link`}
              data-action="agencyLead"
              action={contactModal}
            >
              <Icon name={Mail} color={isMobile ? 'f0' : 'f5'} width="17px" height="12px" className={`${baseCls}__btns__cts--btn--ml`} /> Invia Email
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
AgencySrpCard.propTypes = propTypes;
AgencySrpCard.defaultProps = defaultProps;
const deviceCheck = (prevProps, nextProps) => prevProps.device === nextProps.device;
export default memo(AgencySrpCard, deviceCheck);
