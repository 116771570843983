import {
  getOperation,
  getPartnerIds,
  getTotalResults,
  getTotalPageNumber,
  getAgency,
  getTypology,
  getGeo,
  getResponseCode,
  sortMap,
  listAdsIds,
} from './utils';

export const search = (dl, state, sendResponseCode = false) => {
  let response = {};
  if (sendResponseCode) {
    response = getResponseCode(state);
  }
  if (dl.pageType === 'searchResultPageAgencies') {
    return {
      page: {
        section: 'portal',
        subSection: '',
        type: '',
      },
      event: {
        type: 'view',
        name: '',
      },
      ...response,
      ...getGeo(dl),
    };
  }
  return {
    page: {
      section: 'portal',
      subSection: 'listing',
      type: 'resultList',
    },
    results: {
      type: dl.pageType === 'agencyPage' ? 'agency' : '',
      ads: {
        id: listAdsIds(dl.propertyIds.toString()),
        idId: listAdsIds(getPartnerIds(state).toString()),
      },
      currentPageNumber: dl.pageNumber,
      totalPageNumber: getTotalPageNumber(state),
      totalResult: getTotalResults(state),
      orderBy: sortMap[dl.sortOrder],
    },
    event: {
      type: 'view',
      name: 'viewResults',
    },
    ...getGeo(dl),
    ...getOperation(dl),
    ...getAgency(state),
    ...getTypology(state),
    ...response,
  };
};
