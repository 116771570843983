import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TagsContainer from 'shared-components/components/TagsContainer';
import resetTempFilter from 'shared-utils/src/resetTempFilter';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import { Icon } from 'shared-vectors';
import Trash from 'shared-vectors/icons/NewTrash';
import FilterEditDsk from 'shared-vectors/icons/FilterEditDsk';
import CaretIcon from 'shared-vectors/icons/Caret';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import { getActiveFilters } from '@filters/helpers';
import { getHumanFilters, resetSearchFilters } from '@helpers/search/Search';
import './style.scss';
import DialogConfirm from 'shared-components/components/DialogConfirm';
import { useCtxGlobal } from 'client/app/Container';
import { queryBuilder } from '@modals/factories';

const HeaderTags = ({ filters, updateFilters, forceMobileUpdate }) => {
  const tagsRef = useRef(null);
  const tagsContainerRef = useRef(null);
  const { activeFiltersCount, activeFiltersList } = getActiveFilters(filters);
  const [filtersList, setFiltersList] = useState(
    activeFiltersCount ? getHumanFilters(activeFiltersList) : [],
  );

  const [isOpen, setIsOpen] = useState(false);
  // set the style of the tags container to give scroll-x
  // if the children are bigger than the container
  const [style, setStyle] = useState({ flex: '0 0 auto', overflow: 'hidden' });

  // check if the tags container has a button to open the div
  const [hasBtnOpenDiv, setHasBtnOpenDiv] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const mainCls = 'tags-header';

  const {
    openModal,
    isMobile,
    isSmallMobile,
    filtersProps,
    pageType,
  } = useCtxGlobal();

  useEffect(() => {
    // force the style of the tags container to give scroll-x
    if (!isOpen) {
      setStyle({
        flex: '0 0 auto',
      });
    }
  }, [isOpen]);

  /* get the scroll width of the children of the tags container and
   * get the client width of the tags container(parent)
   * get the scroll height of the children of the tags container
   * 59 is the height of the tags
   */
  useEffect(() => {
    const tagsChildrenScrollWidth = tagsRef.current?.scrollWidth;
    const tagsChildrenScrollHeight = tagsRef.current?.scrollHeight;
    const tagsParentClientWidth = tagsContainerRef.current?.clientWidth;


    // clientWidth that is always the same minus 35px (icon width)
    const parentWidth = tagsParentClientWidth - 35;

    // check if the children are bigger than the container, 59px is the height of the tags
    // 59 is static because of SSR to avoid useless re-render
    const isScrollChildrenHeightHigher = tagsChildrenScrollHeight > 59;

    if (tagsChildrenScrollWidth > parentWidth || isScrollChildrenHeightHigher) {
      !hasBtnOpenDiv && setHasBtnOpenDiv(true);
    } else if (
      tagsChildrenScrollWidth <= tagsParentClientWidth &&
      hasBtnOpenDiv
    ) {
      setHasBtnOpenDiv(false);
      isOpen && setIsOpen(false);
    }
  }, [tagsRef.current?.clientWidth, filtersList.length]);

  useEffect(() => {
    setFiltersList(
      activeFiltersCount ? getHumanFilters(activeFiltersList) : [],
    );
  }, [filters]);

  const preResetTempFilter = (name) => {
    trackGTMEvents(
      {
        category: 'PushButton',
        action: 'DeleteFilter',
        label: name,
        position: 'SRP_TitleArea',
      },
      {},
      true,
    );
    resetTempFilter({
      filterName: name,
      filtersList,
      setFiltersList,
      filters,
      updateFilters,
      forceMobileUpdate,
    });
  };

  const resetFilters = () => {
    setShowConfirm(true);
  };

  const discardReset = () => {
    setShowConfirm(false);
  };

  const openAndResetStyle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setStyle({
        flex: '1 1 auto',
      });
    } else {
      setStyle({
        flex: '0 0 auto',
      });
    }
  };

  const confirmReset = () => {
    setIsOpen(false);
    setShowConfirm(false);
    resetSearchFilters({ filters, updateFilters, forceMobileUpdate: true });
  };

  const goToFiltersModal = () => {
    console.log('**Action clicked: filtersHeaderTags**');
    openModal &&
      openModal(
        queryBuilder({
          isMobile,
          isSmallMobile,
          filtersProps,
          panelChooseBy: {
            isMobile,
            actionClicked: 'filtersHeader',
            pageFrom: pageType,
          },
        }),
      );
  };

  return (
    filtersList.length > 0 && (
      <div className="tags-header-cnt" ref={tagsContainerRef}>
        <div
          className={`tags-header ${!isOpen ? 'box-to-open' : 'box-opened'}`}
        >
          <div ref={tagsRef} className="tags-header__container">
            <TagsContainer
              list={filtersList}
              actionToPerform={preResetTempFilter}
              notShownOn="desktop"
              style={style}
            />
          </div>
          {hasBtnOpenDiv && (
            <div
              onClick={() => openAndResetStyle(!isOpen)}
              className="tags-header__btn"
            >
              <div className="tags-header__transparent-div" />
              <div className="tags-header__btn-svg">
                <Icon
                  name={CaretIcon}
                  height="10px"
                  className={`${isOpen ? 'rotate--180' : ''}`}
                />
              </div>
            </div>
          )}
        </div>
        {isOpen && (
          <Grid
            align="center"
            className={`${mainCls}__cont__footer__cnt pt--ms pb--m`}
          >
            <GridItem
              behavior="fixed"
              className={`${mainCls}__cont__footer__btn tp-s--m tp-w--m tp--u c-txt--f2 is-clickable`}
              // onClick={reset}
              onClick={() => resetFilters()}
            >
              <Icon
                name={Trash}
                width="15px"
                height="16px"
                className="mr--xs"
              />
              Cancella tutti i filtri
            </GridItem>
            <GridItem
              behavior="fixed"
              push="left"
              className={`${mainCls}__cont__footer__btn tp-s--m tp-w--m tp--u c-txt--f2 is-clickable`}
              onClick={goToFiltersModal}
            >
              <Icon
                name={FilterEditDsk}
                width="15px"
                height="16px"
                className="mr--xs"
              />
              Modifica filtri
            </GridItem>
          </Grid>
        )}
        {showConfirm && (
          <DialogConfirm
            confirm={confirmReset}
            discard={discardReset}
            showDialog={showConfirm}
            close={() => setShowConfirm(false)}
          />
        )}
      </div>
    )
  );
};

HeaderTags.propTypes = {
  filters: PropTypes.array,
  updateFilters: PropTypes.func,
  forceMobileUpdate: PropTypes.bool,
};

HeaderTags.defaultProps = {
  filters: [],
  updateFilters: () => {},
  forceMobileUpdate: false,
};

export default HeaderTags;
