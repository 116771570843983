import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SORT_DEFAULT_LABEL,
  SORT_DEFAULT_LABEL_DESKTOP,
} from 'shared-constants/src/filters';
import Icon from '@components/common/Icon';
import SelectList from 'shared-components/components/SelectList';
import './style.scss';
import '../critical.scss';

const ordersButtonsToShow = {
  srp: ['relevance', 'date_desc', 'price_asc'],
  agencies: ['default', 'name_asc', 'name_desc'],
};

const SortComponent = ({
  setSortWrapper,
  sortSelectIsOpen,
  showOnlyFiltersBtn,
  sortValue: sv,
  sortValuesMap,
  toggleSortSelect,
  sortChange,
  viewport,
  isAgencySrp,
}) => {
  const sortValue = sv === 'relevance' && isAgencySrp ? 'default' : sv;

  const { width } = viewport;
  const mainCls = 'btn-sort-exploded';
  const btnSortSelectedCls = `${mainCls}__selected c-txt--f0`;
  const typeSort = isAgencySrp ? 'agencies' : 'srp';
  const otherLabelSelected = !ordersButtonsToShow[typeSort].includes(sortValue);
  const clsMain = `std_sortW${sortSelectIsOpen ? ' select' : ''}${
    showOnlyFiltersBtn ? ' std_sortW--right' : ''
  }
  ${isAgencySrp ? ' std_sortW--agency' : ''}`;

  return (
    <div
      ref={(node) => {
        setSortWrapper(node);
      }}
      className={clsMain}
    >
      {width < 1320 ? (
        <button
          type="button"
          aria-label="Ordina i risultati"
          className="sort-cnt-srp-sort tp-s--m tp-w--m"
          onClick={toggleSortSelect}
        >
          <span className="icon-cont">
            <Icon name="sort" size="m" />
          </span>
          <span className="text">
            {sortValue === Object.keys(sortValuesMap)[0]
              ? SORT_DEFAULT_LABEL
              : sortValuesMap[sortValue]}
          </span>
        </button>
      ) : (
        <div className="sort-cnt-srp">
          <span className="label-sort-default tp-w--s tp-s--m mr--m">
            Ordina per:
          </span>
          {ordersButtonsToShow[typeSort].map(ord => (
            <button
              type="button"
              onClick={() => sortChange(ord, true)}
              className={`${mainCls} ${
                sortValue === ord ? btnSortSelectedCls : ''
              } tp-s--m mr--m tp-w--m`}
            >
              {sortValuesMap[ord]}
            </button>
          ))}
          {!isAgencySrp && (
            <button
              type="button"
              aria-label="Altro"
              className="btn-sort-default tp-s--m tp-w--m"
              onClick={() => toggleSortSelect()}
            >
              <span
                className={`text ${
                  otherLabelSelected ? btnSortSelectedCls : ''
                }`}
              >
                {otherLabelSelected
                  ? sortValuesMap[sortValue]
                  : SORT_DEFAULT_LABEL_DESKTOP}
              </span>
            </button>
          )}
        </div>
      )}
      <SelectList
        valuesItems={sortValuesMap}
        ariaLabelPrefix="Ordina i risultati secondo il parametro: "
        sortChange={sortChange}
        sortValue={sortValue}
        isOpen={sortSelectIsOpen}
      />
    </div>
  );
};

SortComponent.propTypes = {
  setSortWrapper: PropTypes.func,
  sortSelectIsOpen: PropTypes.bool,
  showOnlyFiltersBtn: PropTypes.bool,
  sortValue: PropTypes.string,
  sortValuesMap: PropTypes.object,
  toggleSortSelect: PropTypes.func,
  sortChange: PropTypes.func,
  viewport: PropTypes.object,
  isAgencySrp: PropTypes.bool,
};

SortComponent.defaultProps = {
  setSortWrapper: () => {},
  sortSelectIsOpen: false,
  showOnlyFiltersBtn: false,
  sortValue: '',
  sortValuesMap: {},
  toggleSortSelect: () => {},
  sortChange: () => {},
  viewport: {},
  isAgencySrp: false,
};

export default SortComponent;
