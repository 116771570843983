import React from 'react';
import PropTypes from 'prop-types';

import SkeletonBox from 'shared-components/components/SkeletonBox';
import SkeletonLine from 'shared-components/components/SkeletonLine';

/*
/vendita/commerciale/milano/
/vendita/attivita-licenze-commerciali/milano/
/vendita/locali-commerciali/milano/
/vendita/locali-commerciali/doues/
/srp/?tr=vendita&air_conditioned=true&sortType=relevance&propertyTypeGroup=commerciale&q=4e836643
/srp/?tr=vendita&propertyTypes=locale+commerciale&air_conditioned=true&sortType=relevance&propertyTypeGroup=commerciale&q=4e836643
/srp/?tr=vendita&propertyTypes=attivita+licenza+commerciale&air_conditioned=true&sortType=relevance&propertyTypeGroup=commerciale&q=4e836643
*/

const propTypes = {
  open: PropTypes.bool,
  isPublisherPage: PropTypes.bool,
  isAgencySrp: PropTypes.bool,
  showPTypesButton: PropTypes.bool,
  showLicenceTypeGroupsButton: PropTypes.bool,
  showLicenceContractTypeGroupsButton: PropTypes.bool,
  hasPois: PropTypes.bool,
  locationHasChildren: PropTypes.bool,
  skBox1: PropTypes.number,
  skBox2: PropTypes.number,
  skBox3: PropTypes.number,
  skBox4: PropTypes.number,
  skBox5: PropTypes.number,
};

const defaultProps = {
  open: false,
  isPublisherPage: false,
  isAgencySrp: false,
  showPTypesButton: false,
  showLicenceTypeGroupsButton: false,
  showLicenceContractTypeGroupsButton: false,
  hasPois: true,
  locationHasChildren: true,
  skBox1: 0,
  skBox2: 0,
  skBox3: 0,
  skBox4: 0,
  skBox5: 0,
};

const Skeleton = ({
  open,  
  isPublisherPage,
  isAgencySrp,
  showPTypesButton,
  showLicenceTypeGroupsButton,
  showLicenceContractTypeGroupsButton,
  hasPois,
  locationHasChildren,
  skBox1,
  skBox2,
  skBox3,
  skBox4,
  skBox5,
}) => (
  <div className="is-vis-from-s">
    {
      !isAgencySrp
        ? (
          <SkeletonBox className="c-bg--w pr--m pl--m" mb="10" width="100%">
            <SkeletonLine mt="15" mb="14" width="100%" height="12" />
            {
              isPublisherPage
                && (
                  <>
                    {/* nome agenzia 40px */}
                    <SkeletonLine mt="14" mb="14" width="100%" height="12" />
                  </>
                )
            }
            {/* channel 41px */}
            <SkeletonLine mt="14" mb="15" width="100%" height="12" />       
            {/* location 40px */}
            <SkeletonLine mt="14" mb="15" width="100%" height="12" />
            {/* children location 40px */}
            {locationHasChildren && <SkeletonLine mt="14" mb="14" width="100%" height="12" />}            
          </SkeletonBox>
        ) : (
          <SkeletonBox className="c-bg--w pr--m pl--m" width="100%">
            {[...Array(locationHasChildren ? 5 : 4).keys()].map((a, i) => <SkeletonLine key={`top_${i + 1}`} mt={i < 3 ? '15' : '14'} mb="14" width="100%" height="12" />)}
          </SkeletonBox>
        )
    }
    {/* ptypes 41px */}
    {showPTypesButton && (
      <>
        <SkeletonBox mb="10" className="c-bg--w pr--m pl--m" width="100%">
          <SkeletonLine mt="14" mb="15" width="100%" height="12" />
        </SkeletonBox>
        {showLicenceTypeGroupsButton && (
          <SkeletonBox mb="10" className="c-bg--w pr--m pl--m" width="100%">        
            <SkeletonLine mt="14" mb="15" width="100%" height="12" />
          </SkeletonBox>
        )}
      </>
    )}
    {showLicenceTypeGroupsButton && !showPTypesButton && (
      <SkeletonBox mb="10" className="c-bg--w pr--m pl--m" width="100%">
        <SkeletonLine mt="14" mb="15" width="100%" height="12" />
      </SkeletonBox>
    )}
    {
      !isAgencySrp
        && (
          <>
            <SkeletonLine mt="28" mb="19" ml="16" width="80" height="12" />
            {/* pois 42px */}
            {
              hasPois
                && (
                  <SkeletonBox mb="16" className="c-bg--w pr--m pl--m" width="100%">
                    <SkeletonLine mt="15" mb="15" width="100%" height="12" />
                  </SkeletonBox>
                )
            }            
            <SkeletonBox className="c-bg--w pr--m pl--m" width="100%" mb="16">
              <>
                {[...Array(skBox1).keys()].map((a, i) => <SkeletonLine key={`bot_${i + 1}`} mt="15" mb={i === skBox1 - 1 ? '15' : 14} width="100%" height="12" />)}
                { !open && <SkeletonLine mt="10" mb="11" width="70" height="12" />}
              </>
            </SkeletonBox>
          </>
        )
    }
    {
      !isAgencySrp
        && open
        ? (
          <>
            {skBox2 > 0 && (
              <SkeletonBox className="c-bg--w pr--m pl--m" width="100%" mb="16">
                {[...Array(skBox2).keys()].map((a, i) => <SkeletonLine key={`bot_${i + 1}`} mt="15" mb={i === skBox2 - 1 ? '15' : 14} width="100%" height="12" />)}
              </SkeletonBox>
            )}
            {skBox3 > 0 && (
              <SkeletonBox className="c-bg--w pr--m pl--m" width="100%" mb="16">
                {[...Array(skBox3).keys()].map((a, i) => <SkeletonLine key={`bot_${i + 1}`} mt="15" mb={i === skBox3 - 1 ? '15' : 14} width="100%" height="12" />)}
              </SkeletonBox>
            )}
            {skBox4 > 0 && (
              <SkeletonBox className="c-bg--w pr--m pl--m" width="100%" mb="16">
                {[...Array(skBox4).keys()].map((a, i) => <SkeletonLine key={`bot_${i + 1}`} mt="15" mb={i === skBox4 - 1 ? '15' : 14} width="100%" height="12" />)}
              </SkeletonBox>
            )}
            {skBox5 > 0 && (
              <SkeletonBox className="c-bg--w pr--m pl--m" width="100%" mb="16">
                {[...Array(skBox5).keys()].map((a, i) => <SkeletonLine key={`bot_${i + 1}`} mt="15" mb={i === skBox5 - 1 ? '15' : 14} width="100%" height="12" />)}
              </SkeletonBox>
            )}
          </>
        ) : null
    }
  </div>
);
Skeleton.propTypes = propTypes;
Skeleton.defaultProps = defaultProps;
export default Skeleton;
