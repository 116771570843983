import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Button from 'shared-components/components/Button';

import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import './style.scss';

const ValutaTuBanner = (props) => {

  const { id, scrollInfos, setValutaTuBannerVisibile } = props;
  const scrollEl = useRef(null);
  const [offSetTop, setOffSetTop] = useState(null);

  const valutaTuUrl = '/valutazione-immobile/';
  
  useEffect(
    () => {
      if (
        offSetTop
        && scrollInfos.coords + (document.documentElement.clientHeight / 2) >= offSetTop
      ) {
        
        setValutaTuBannerVisibile();
      }
    }, [scrollInfos, offSetTop],
  );

  useEffect(
    () => {
      const {
        current: {
          offsetTop,
        },
      } = scrollEl;
      setOffSetTop(offsetTop);
    }, [],
  );

  const mainCls = makeClassName([
    'dwnBanner c-bg--w b--s tp-s--m mb--m',
    'pr--ml pl--ml',
    'ml--s mr--s tp-a--c',
  ]);

  const titCls = makeClassName([
    'dwnBanner__tit dwnBanner__txt',
    'tp-w--l tp-s--l',
  ]);
  const valutaTuClick = () => {
    sleep(300).then(() => trackGTMEvents({
      category: 'Interaction',
      action: 'PushButton',
      label: 'GoToValuation_SRP',
    }, null, true));
    window.open(valutaTuUrl);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Grid
      id={id}
      className={mainCls}
      direction="column"
    >
      <GridItem>
        <div className="dwnBanner__strip" />
      </GridItem>
      <GridItem className="dwnBanner__icon" />
      <GridItem>
        <div className="dwnBanner__strip" />
      </GridItem>
      <GridItem className="dwnBanner__ctn">
        <strong ref={scrollEl} className={titCls}>Valuta la tua casa</strong>
        <p className="dwnBanner__txt tp-w--s tp-s--l">Sai quanto vale la tua casa?</p>
      </GridItem>
      <GridItem>
        <div className="dwnBanner__strip" />
      </GridItem>
      <GridItem behavior="fixed" align="middle">
        <Button
          className="dwnBanner__btn"
          action={valutaTuClick}
        >
          <span>Scoprilo subito, è gratis!</span>
        </Button>
      </GridItem>
      <GridItem>
        <div className="dwnBanner__strip" />
      </GridItem>
    </Grid>
  );
};
ValutaTuBanner.propTypes = {
  id: PropTypes.string,
  scrollInfos: PropTypes.instanceOf(Object),
  setValutaTuBannerVisibile: PropTypes.func,
};
ValutaTuBanner.defaultProps = {
  id: 'vtb',
  scrollInfos: {},
  setValutaTuBannerVisibile: () => { },
};
export default ValutaTuBanner;
