/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import loadScript from 'shared-utils/src/loadScript';
import readCookie from 'shared-utils/src/cookies/readCookie';

// function log(...values) {
//   console.// log('ADV Manager:', ...values);
// }

export function getSafeGPT() {
  const googletag = window.googletag || {};
  const cmd = googletag.cmd || [];
  window.googletag = googletag;
  googletag.cmd = cmd;
  return { googletag, cmd };
}

export function createGPTSlot(t, o, e) {
  // log('createGPTSlot', { t, o, e });
  const { dfpVars = {} } = window;
  const n = [];

  for (let i = 0; i < e.sizes.length; i++) {
    n.push([e.sizes[i].width, e.sizes[i].height]);
  }

  const slot = googletag.defineSlot(dfpVars.dfpChannel, n, o);

  if (slot) {
    gptSlots[t] = slot.addService(googletag.pubads());

    for (const s in gptSlots[t], e.targets) {
      e.targets.hasOwnProperty(s) && gptSlots[t].setTargeting(s, [e.targets[s]]);
    }

    gptSlots[t].setTargeting('ord', [dfpVars.ord]);
    gptSlots[t].setTargeting('regione', [dfpVars.regione]);
    gptSlots[t].setTargeting('provincia', [dfpVars.provincia]);
    gptSlots[t].setTargeting('comune', [dfpVars.comune]);
    gptSlots[t].setTargeting('zone', [dfpVars.zone]);
    gptSlots[t].setTargeting('Quartiere', [dfpVars.block]);
    gptSlots[t].setTargeting('price', [dfpVars.price]);
    dfpVars.agent && gptSlots[t].setTargeting('agent', [dfpVars.agent]);
    dfpVars.detailDepth && gptSlots[t].setTargeting('detailDepth', [dfpVars.detailDepth]);
    dfpVars.agentType && gptSlots[t].setTargeting('agentType', [dfpVars.agentType]);
    dfpVars.propertyTypes && dfpVars.propertyTypes !== 'null' && gptSlots[t].setTargeting('Tipologia', dfpVars.propertyTypes.split(','));
    dfpVars.buildingCondition && dfpVars.buildingCondition !== 'null' && gptSlots[t].setTargeting('condizioniImmobile', dfpVars.buildingCondition.split(','));
    dfpVars.features && dfpVars.features !== 'null' && gptSlots[t].setTargeting('features', dfpVars.features.split(','));
  }
}

export function loadAdvPositions() {
  const { dfpVars = {} } = window;
  const { positions = [] } = dfpVars;
  positions && positions.length > 0 && googletag && googletag.cmd && googletag.cmd.push(() => {
    for (let n = 0; n < positions.length; n += 1) {
      const t = positions[n];
      const o = dfpVars.positionConfigurations[t];
      o.lazyLoad || createGPTSlot(n, t, o);
    }
    googletag.pubads().disableInitialLoad();
    googletag.pubads().setTargeting('sect', [dfpVars.casaSection]);
    googletag.pubads().setTargeting('channel', [dfpVars.casaChannel]);
    googletag.pubads().setTargeting('type', [dfpVars.casaCategory]);
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();
    googletag.pubadsReady ? googletag.pubads().refresh() : setTimeout(() => {
      googletag.pubads().refresh();
    }, 550);
  });
}

export function displayLazySlot(t) {
  googletag && googletag.apiReady && googletag.pubadsReady && googletag.pubads && googletag.pubads().refresh([gptSlots[t]]);
}

export function loadAdvLazyPositions() {
  const { dfpVars = {} } = window;
  const { positions = [], positionConfigurations = {} } = dfpVars;

  if (positions.length > 0) {
    const t = Object.keys(positionConfigurations).filter(i => positionConfigurations[i].lazyLoad && positions.indexOf(i) > -1);
    const o = gptSlots.length > 0 ? gptSlots.length : 0;
    t && t.length > 0 && googletag && googletag.cmd && googletag.cmd.push(() => {
      let e = 0;

      for (let index = o; index < t.length + o; index += 1) {
        const n = t[e];
        const i = positionConfigurations[n];
        createGPTSlot(index, n, i);
        e += 1;
        displayLazySlot(index);
      }
    });
  }
}

export function loadLazyAdv() {
  getSafeGPT().cmd.push(loadAdvLazyPositions);
}

export const loadGPT = ({ setShowSaveSearchBannerGreenHint = () => { } } = {}) => new Promise((res, rej) => {
  // log('Setup');
  if (window.startLoadGPT) return rej('GPT load already started');
  if (window.googletag) return res();
  window.startLoadGPT = 1;

  window.googletag = window.googletag || {};
  googletag.cmd = googletag.cmd || [];
  googletag.cmd.push(() => {
    window.startLoadGPT = 0;
    // log('GPT loaded');
    loadAdvPositions();
    res();
  });

  const load = () => {
    /**
     * we set the hint to true to show the tooltip
     * after the CMP is updated or laoded
     */
    setShowSaveSearchBannerGreenHint(true);
    loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js').catch(rej);
    window.removeEventListener('scroll', load);
  };
  const onCmpUpdate = () => {
    // const closed = readCookie('OptanonAlertBoxClosed');
    // log(`CMP data: ${closed ? 'available' : 'not available'} - ${closed}`);
    // if (closed) {
      // log('CMP closed');
    load();
    window.removeEventListener('updatedCMP', onCmpUpdate);
    // }
  };

  // log('App Listener');
  window.addEventListener('updatedCMP', onCmpUpdate);

  // log('Set DL variable');
  window.dataLayer.push({
    event: 'CsaReadyToLoadGTP',
    CsaReadyToLoadGTP: true,
  });

  // if (device && device !== 'desktop') {
  //   // log('load GPT on scroll');
  //   window.addEventListener('scroll', load);
  // } else {
  //   load();
  // }
});
