import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'shared-components/components/Grid';

import './style.scss';

const propTypes = {
  title: PropTypes.string,
};

const defaultProps = {
  title: 0,
};


const RecentAdsTitle = ({
  title,
}) => (
  <Grid className="recent-ads-title mt--m mb--s" direction="column">
    <p className="recent-ads-title__ads_text tp-w--l tp-s--xl">{title}</p>
  </Grid>
);
RecentAdsTitle.propTypes = propTypes;
RecentAdsTitle.defaultProps = defaultProps;

export default RecentAdsTitle;
