import React from 'react';
import PropTypes from 'prop-types';

import integerWithThousandSeparator from 'shared-utils/src/integerWithThousandSeparator';

import { CtxContainer } from '@client/app/Container';
import { queryBuilder } from '@modals/factories';

import Breadcrumb from '../Breadcrumb';

import './critical.scss';
import BoxHeaderSrp from './BoxHeaderSrp';

class Heading extends React.PureComponent {
  static contextType = CtxContainer;

  openQueryBuilder = () => {
    const {
      openModal,
      filtersProps,
      isMobile,
      isSmallMobile,
      pageType,
    } = this.context;
    openModal &&
      openModal(
        queryBuilder({
          isMobile,
          isSmallMobile,
          filtersProps,
          panelChooseBy: {
            isMobile,
            actionClicked: 'filtersHeader',
            pageFrom: pageType,
          },
        }),
      );
  };

  render() {
    const {
      isMobile,   
      isDesktop, 
      model: { fewResults },
      siteUrl,
    } = this.context;

    const {
      locations,
      title,
      availableResults,
      h2,
      aliasH2,
      breadCrumbDropDownData,
      filters,
      updateFilters,
      setForcedAuction,
      checkProposeBkmAfterLead,
      userLogIn,
      publisherName,
      showSaveSearchButton,
      showSaveSearchBannerGreenHint,
    } = this.props;

    const count =
      availableResults > 0
        ? `${integerWithThousandSeparator(availableResults)} `
        : '';

    return (
      <>
        <Breadcrumb
          locations={locations}
          data={breadCrumbDropDownData}
          siteUrl={siteUrl}
          isDesktop={isDesktop}
        />
        <BoxHeaderSrp
          openQueryBuilder={this.openQueryBuilder}
          title={title}
          h2={h2}
          aliasH2={aliasH2}
          count={count}
          fewResults={fewResults}
          isMobile={isMobile}
          filters={filters}
          updateFilters={updateFilters}
          setForcedAuction={setForcedAuction}
          checkProposeBkmAfterLead={checkProposeBkmAfterLead}
          userLogIn={userLogIn}
          publisherName={publisherName}
          showSaveSearchButton={showSaveSearchButton}
          showSaveSearchBannerGreenHint={showSaveSearchBannerGreenHint}
          
        />
      </>
    );
  }
}

export default Heading;

Heading.propTypes = {
  locations: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  availableResults: PropTypes.number,
  h2: PropTypes.string,
  aliasH2: PropTypes.string,
  breadCrumbDropDownData: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Object),
  updateFilters: PropTypes.func,
  userLogIn: PropTypes.func,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  publisherName: PropTypes.string,
  showSaveSearchButton: PropTypes.bool,
  showSaveSearchBannerGreenHint: PropTypes.bool,
};

Heading.defaultProps = {
  locations: [],
  title: '',
  availableResults: 0,
  h2: '',
  aliasH2: '',
  breadCrumbDropDownData: {},
  filters: {},
  updateFilters: () => {},
  userLogIn: () => {},
  setForcedAuction: () => {},
  checkProposeBkmAfterLead: () => {},
  publisherName: null,
  showSaveSearchButton: true,
  showSaveSearchBannerGreenHint: false,
};
