import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@components/common/Icon';

import './PublisherPaginator.scss';

const propTypes = {
  numResults: PropTypes.number,
  publisherId: PropTypes.number,
  listLength: PropTypes.number,
};
const defaultProps = {
  numResults: 0,
  publisherId: 0,
  listLength: 0,
};

const PublisherPaginator = ({
  numResults,
  publisherId,
  listLength,
}) => {
  const fakeLink = (evt) => {
    const url = evt.target.getAttribute('data-url');
    if (url) {
      document.location = url;
    }
  };
  return (
    <div className="publisherPaginator">
      <p className="publisherPaginator__p">
        <strong className="publisherPaginator__p__b">1</strong> - <strong className="publisherPaginator__p__b">{listLength}</strong> di <span className="publisherPaginator__p__span">{numResults}</span> annunci
      </p>
      <button
        type="button"
        className="publisherPaginator__button"
        data-url={`/srp/?pId=${publisherId}`}
        onClick={fakeLink}
      >
        Vedi tutti gli annunci <Icon name="caret" className="publisherPaginator__button__arrow" />
      </button>
    </div>
  );
};
PublisherPaginator.propTypes = propTypes;
PublisherPaginator.defaultProps = defaultProps;
export default PublisherPaginator;
