import { filterBoxesConfiguration } from 'shared-constants/src/filters/groups';
import { getProvinceShortName } from '../../client/helpers/search/Search';
import * as helpers from './helpers';

// eslint-disable-next-line prefer-destructuring
export const channelConversion = helpers.channelConversion;

function searchGTMHelper({
  search,
  isMobileInfiniteScroll = false,
  variantTestValue = null,
}) {
  const {
    queryFilters,
    resolvedLocations,
    list,
    isPublisherHP,
    isAgencySrp,
    scrollPage,
    georeachList,
    fewResults,
  } = search;
  let pageType = 'searchResults';
  if (isPublisherHP) {
    pageType = 'agencyPage';
  } else if (isAgencySrp) {
    pageType = 'searchResultAgencies';
  }

  let georeachIds = helpers.NOT_AVAILABLE;
  let propertyCountry = helpers.NOT_AVAILABLE;
  let propertyRegion = helpers.NOT_AVAILABLE;
  let propertyDepartment = helpers.NOT_AVAILABLE;
  let propertyProvince = helpers.NOT_AVAILABLE;
  let propertySubzone = helpers.NOT_AVAILABLE;
  let propertyIndustrialArea = helpers.NOT_AVAILABLE;
  let propertyCity = helpers.NOT_AVAILABLE;
  let propertyZone = helpers.NOT_AVAILABLE;
  let propertyLocality = helpers.NOT_AVAILABLE;
  let searchSubtype = helpers.NOT_AVAILABLE;
  let propertyNonadminZone = helpers.NOT_AVAILABLE;
  let propertyNonadminSubzone = helpers.NOT_AVAILABLE;

  const isFreeText = resolvedLocations.length === 1 && helpers.freeTextTypes.indexOf(resolvedLocations[0].type) > -1;

  if (!isFreeText) {
    resolvedLocations.forEach(
      (location) => {
        let locality = '';
        let needsCountry = true;
        if (
          helpers.freeTextTypes.indexOf(location.type) < 0
          && 'levels' in location
        ) {
          const processedLocation = helpers.extractLocalityData(location);
          if ('propertySubzone' in processedLocation) {
            locality += `${processedLocation.propertySubzone}, `;
            propertySubzone = helpers.prepareLocationTypeSegment(processedLocation.propertySubzone, propertySubzone);
            needsCountry = false;
          }          
          if ('propertyZone' in processedLocation) {
            locality += `${processedLocation.propertyZone} in `;
            propertyZone = helpers.prepareLocationTypeSegment(processedLocation.propertyZone, propertyZone);
            needsCountry = false;
          }
          if ('propertyCity' in processedLocation) {
            locality += `${processedLocation.propertyCity}, `;
            propertyCity = helpers.prepareLocationTypeSegment(processedLocation.propertyCity, propertyCity);
            needsCountry = false;
          }
          if ('propertyProvince' in processedLocation) {
            locality += `(${getProvinceShortName(processedLocation.propertyProvince)}), `;
            propertyProvince = helpers.prepareLocationTypeSegment(processedLocation.propertyProvince, propertyProvince);
            needsCountry = false;
          }
          if ('propertyIndustrialArea' in processedLocation) {
            locality += `${processedLocation.propertyIndustrialArea}, `;
            propertyIndustrialArea = helpers.prepareLocationTypeSegment(processedLocation.propertyIndustrialArea, propertyIndustrialArea);
            needsCountry = false;
          }
          if ('propertyDepartment' in processedLocation) {
            locality += `${processedLocation.propertyDepartment}, `;
            propertyDepartment = helpers.prepareLocationTypeSegment(processedLocation.propertyDepartment, propertyDepartment);
            needsCountry = false;
          }
          if ('propertyRegion' in processedLocation) {
            locality += `${processedLocation.propertyRegion}`;
            propertyRegion = helpers.prepareLocationTypeSegment(processedLocation.propertyRegion, propertyRegion);
            needsCountry = false;
          }
          if ('propertyCountry' in processedLocation) {
            if (needsCountry) {
              locality += `, ${processedLocation.propertyCountry}`;
            }
            propertyCountry = helpers.prepareLocationTypeSegment(processedLocation.propertyCountry, propertyCountry);
          }
          propertyLocality = helpers.prepareLocationTypeSegment(locality, propertyLocality);
        }
      },
    );

    if (resolvedLocations.length && resolvedLocations[0].level) {
      searchSubtype = helpers.getSearchSubType(resolvedLocations[0]);  
      if (resolvedLocations[0].level === 118 || resolvedLocations[0].level === 130) {
        propertyNonadminZone = resolvedLocations[0].name;
      }
  
      if (resolvedLocations[0].level === 120) {
        propertyNonadminZone = resolvedLocations[0].levels.L118;
        propertyNonadminSubzone = resolvedLocations.map(rl => rl.name).join('_');
      }
      if (resolvedLocations[0].level === 132) {
        propertyNonadminZone = resolvedLocations[0].levels.L130;
        propertyNonadminSubzone = resolvedLocations.map(rl => rl.name).join('_');
      }
    }
  }

  let ptypeGroup = 'propertyTypeGroup' in queryFilters
    ? queryFilters.propertyTypeGroup
    : null;
  if ('category' in queryFilters && !ptypeGroup) {
    ptypeGroup = queryFilters.category;
  }

  const siteSection = 'tr' in queryFilters
    ? helpers.channelConversion[queryFilters.tr]
    : 'buy';

  const tenureType = siteSection;

  const useType = 'propertyTypeGroup' in queryFilters
    ? helpers.useTypeConversion[queryFilters.propertyTypeGroup]
    : 'residential';

  const propertyTypeGroup = ptypeGroup || 'case';

  const propertyType = 'propertyTypes' in queryFilters
    ? helpers.extractPropertyTypes(queryFilters.propertyTypes)
    : helpers.NOT_AVAILABLE;

  const ptypesAll = 'propertyTypes' in queryFilters
    ? queryFilters.propertyTypes
    : helpers.NOT_AVAILABLE;

  const priceRange = helpers.extractRange('price', queryFilters);
  const sizeRange = helpers.extractRange('mq', queryFilters);
  const roomsCount = helpers.extractRange('numRooms', queryFilters);

  const sortOrder = 'sortType' in queryFilters
    ? queryFilters.sortType
    : 'relevance';

  const publisherId = 'pId' in queryFilters
    ? queryFilters.pId.toString()
    : helpers.NOT_AVAILABLE;

  let pageNumber = 'page' in queryFilters
    ? queryFilters.page.toString()
    : helpers.NOT_AVAILABLE;

  if (isMobileInfiniteScroll) {
    pageNumber = scrollPage;
  }

  let searchType;
  try {
    searchType = helpers.getSearchType({
      ...queryFilters,
      localities: propertyLocality !== helpers.NOT_AVAILABLE,
    }) || helpers.NOT_AVAILABLE;
  } catch (error) {
    searchType = helpers.NOT_AVAILABLE;
  }

  let variant;
  if (variantTestValue) {
    variant = variantTestValue;
  }

  if ('sellerType' in queryFilters && !Array.isArray(queryFilters.sellerType)) {
    queryFilters.sellerType = queryFilters.sellerType.split(',');
  }

  const publisherType = 'sellerType' in queryFilters
    ? queryFilters.sellerType.join('_')
    : helpers.NOT_AVAILABLE;

  let propertyIds = helpers.NOT_AVAILABLE;
  let depthLevel = helpers.NOT_AVAILABLE;
  if (list.length > 0) {
    const propertyIdsArr = [];
    let startIndex = 0;
    let endIndex = list.length - 1;
    if (isMobileInfiniteScroll) {
      startIndex += (scrollPage - 1) * 20;
      endIndex = startIndex + 20;
    }
    for (let i = startIndex; i <= endIndex; i += 1) {
      if (list[i]) {
        propertyIdsArr.push(list[i].id.toString());
        if (depthLevel === helpers.NOT_AVAILABLE) {
          depthLevel = 'depth' in list[i]
            ? list[i].depth.trim()
            : helpers.NOT_AVAILABLE;
        }
      }
    }
    propertyIds = propertyIdsArr.join('_');
  }

  // check if user applied any search filter
  const allowedFilters = [...new Set(
    [].concat(
      ...Object.keys(filterBoxesConfiguration).map(sect => Object.keys(filterBoxesConfiguration[sect])),
      helpers.notStandardFilters,
    ),
  )];

  let userFilters = Object.keys(queryFilters).filter(qf => helpers.qfNotFilters.indexOf(qf) < 0);
  userFilters = userFilters.filter(f => allowedFilters.indexOf(f) > -1);

  let extraData = userFilters.length === 0
    ? 'notFiltered'
    : `filtered${userFilters.length}`;
  // no results
  if (search.total === 0) {
    extraData = `zero-results_${extraData}`;
  // fewer than 10 results
  } else if (search.total < 100) {
    extraData = `few-results_${extraData}`;
  }
  
  if (georeachList && georeachList.length > 0) {
    georeachIds = georeachList.map(item => item.id).join('_');
    extraData = `${extraData}_ShowNewProjects`;
  }

  if (fewResults && fewResults.total) {
    extraData = `${extraData}_hasPropertiesAround${fewResults.total}`;
  }

  return {
    pageType,
    siteSection,
    tenureType,
    useType,
    propertyTypeGroup,
    propertyCountry,
    propertyRegion,
    propertyDepartment,
    propertySubzone,
    propertyIndustrialArea,
    propertyProvince,
    propertyCity,
    propertyZone,
    propertyLocality,
    propertyType,
    ptypesAll,
    priceRange,
    sizeRange,
    roomsCount,
    publisherId,
    publisherType,
    sortOrder,
    depthLevel,
    propertyIds,
    extraData,
    variant,
    pageNumber,
    searchType,
    georeachIds,
    searchSubtype,
    propertyNonadminZone,
    propertyNonadminSubzone,
  };
}

export default searchGTMHelper;
